import React from 'react';
import FooterMenu from "./footerMenu";
import Copyright from "./copyright";
import FooterContact from "./footerContact";


const Footer = () => {
    return (
        <div className="">
           <FooterMenu/>
           <FooterContact/>
            <Copyright/>
        </div>
    );
};

export default Footer;