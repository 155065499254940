import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import {BreadCrumb} from "../components/general/breadcrumb";
import {Sanitized} from "../components/general/sanitize";
import LinkResource from "../components/linkResource";
import {useTranslation} from "react-i18next";
import {api, newsUrl, resources, uri} from "../api/const";
import {useFetch} from "../api/useFetch";
import {CreateMarkup, CustomDompurify} from "../components/general/dompurify";
import DOMPurify from "dompurify";

const AdvancementPage = () => {
    const { isLoading, response } = useFetch( resources + `advancement-info/`);
    const {t,i18n} = useTranslation()
    window.scrollTo(0, 0);

    if (isLoading) {
        return (
            <div role="status" className='flex justify-center my-28 pb-24'>
                <ClipLoader
                    color="#1985A1"
                    size={300}
                />
            </div>
        )
    }


    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
        if (node.tagName === 'A') {
            node.classList.add('links');
        }
        if (node.nodeName === 'SPAN' && node.style.color === '#3498db') {
            node.nodeValue = node.nodeValue.replace(/\r\n\r\n/g, '\n');
        }
    });

    return (
        <div className="wrapper min-h-[53vh] py-[62px] 2md:py-[32px]">
            <div className="w-full">
                <div className="title">{t('progress')}</div>
                <BreadCrumb/>
            </div>
            {response.results &&
                <div>
                    <div
                        className="flex max-w-[1236px] m-auto rounded bg-white mb-10 items-center">
                        {response.results.map(item => (
                            <div className="text-content" key={item.id}>
                                <CustomDompurify html={item[`data_${i18n.language}`].replace(/\r\n\r\n/g, "<br/>")}/>
                            </div>
                        ))}
                    </div>
                    <>
                        {/*{response.map(item => (*/}
                        {/*    <div>*/}
                        {/*        <div className="title-link">{t('resource-link')}</div>*/}
                        {/*        <div>*/}
                        {/*            {item.advancement_links.map(item => (*/}
                        {/*                <div className="text-link" onClick={() => window.open(item.link)}>{item.link}</div>*/}
                        {/*            ))}*/}
                        {/*        </div>*/}

                        {/*    </div>*/}
                        {/*))}*/}
                    </>
                </div>
            }
            {/*<LinkResource props={response.link}/>*/}
        </div>
    );
};

export default AdvancementPage;