import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useFetch} from "../../../api/useFetch";
import {limit2, main, url} from "../../../api/const";
import {Sanitized} from "../../general/sanitize";
import React from "react";
import date from "../../../assets/image/home/clock.svg";


export const Solution = () => {
    const { response } = useFetch(main + `get-decisions/`);
    const { t, i18n } = useTranslation();

    return (
      <div className="wrapper py-[62px] max-w-[384px] 4xs:max-w-[270px] relative z-10 text-general">
        <div className="flex wrapper justify-between mb-[32px] px-[26px] 3xs:px-0">
          <p className="title ml-2">{t('investigate')}</p>
          <Link to="/investigate" className="all-view mr-2">
            {t('all')}
          </Link>
        </div>
        <div className="flex justify-arround wrapper w-full justify-center">
          {/*big cart, hide tablet*/}
          <div className="lg:hidden">
            {response &&
              response.slice(0, 1).map((item) => (
                <Link
                  to={`investigate/${item.id}`}
                  className="block mb-8 w-[500px] h-[500px] xl:h-[400px] shadow-xl rounded bg-white pb-4 leading-5 cursor-pointer hover:shadow-2xl lg:w-[300px] 2lg:w-[340px] xl:w-[400px] 1sm:w-[384px] xs:w-[300px] 4xs:w-[270px]"
                >
                  <div className="mb-3 w-full h-[300px] xl:h-[220px] overflow-hidden rounded-t">
                    <img
                      src={url + item.decision_slider_image[0].image}
                      alt="cart-img"
                      className="h-auto w-[100%] rounded-t"
                    />
                  </div>
                  <div className="px-2.5">
                    <p className="cart-title">
                      {item[`title_${i18n.language}`].length > 90
                        ? item[`title_${i18n.language}`].slice(0, 90) + '...'
                        : item[`title_${i18n.language}`]}
                    </p>
                    <p className="solution-content mt-4">
                      <Sanitized html={item[`short_desc_${i18n.language}`]} />
                    </p>
                    <div className="flex flex-wrap mt-[8px] 2md:text-[12px] 1xs:mt-[8px]">
                      {item.date && (
                        <div className="flex">
                          <img src={date} alt="clock" className="cart-icon" />
                          <p className="text-sm font-medium 3sm:text-[12px]">
                            {item.date.split('-').reverse().join('.')}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
          </div>

          <div className="flex flex-wrap justify-between w-[664px] xl:w-[700px] 3lg:w-[620px] justify-center ">
            {response &&
              response.slice(1, 5).map((item, index) => (
                <Link
                  to={`investigate/${item.id}`}
                  className={`block mb-8 w-[316px] h-[234px] shadow-xl rounded bg-white pb-4 leading-5 cursor-pointer hover:shadow-2xl xl:w-[340px] 3lg:w-[300px] lg:w-[300px] 1sm:w-[384px] p-1 xs:w-[300px] m-auto`}
                >
                  <div className="mb-3 w-full h-[162px] overflow-hidden rounded-t">
                    <img
                      src={url + item.decision_slider_image[0].image}
                      alt="cart-img"
                      className="h-auto w-[100%] rounded-t"
                    />
                  </div>
                  <div className="px-2.5">
                    <p className="cart-title">
                      {item[`title_${i18n.language}`].length > 60
                        ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                        : item[`title_${i18n.language}`]}
                    </p>
                  </div>
                </Link>
              ))}
          </div>
          {/*show tablet*/}
          <div className="hidden lg:block m-auto">
            {response &&
              response.slice(0, 1).map((item, index) => (
                <Link
                  to={`investigate/${item.id}`}
                  className={`block mb-8 w-[316px] h-[234px] shadow-xl rounded bg-white pb-4 leading-5 cursor-pointer hover:shadow-2xl xl:w-[340px] 3lg:w-[300px] lg:w-[300px] 1sm:w-[384px] xs:w-[300px]`}
                >
                  <div className="mb-3 w-full h-[162px] overflow-hidden rounded-t">
                    <img
                      src={url + item.decision_slider_image[0].image}
                      alt="cart-img"
                      className="h-auto w-[100%] rounded-t"
                    />
                  </div>
                  <div className="px-2.5">
                    <p className="cart-title">
                      {item[`title_${i18n.language}`].length > 60
                        ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                        : item[`title_${i18n.language}`]}
                    </p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    );
};