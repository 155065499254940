import React, {useState} from 'react';
import earth from "../../assets/image/home/Icon.svg";
import lang from "../../assets/image/home/chevron-down.svg";
import {changeLanguage} from "../../i18next";
import {useTranslation} from "react-i18next";
import {useMatchMedia} from "../../hooks/useMatchMedia";

export const Language = () => {
    const [openModal, setOpenModal] = useState(false);
    const {t} = useTranslation();
    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    const { isMobile, isTablet, isDesktop } = useMatchMedia();

    const onChange = (lng) => {
        changeLanguage(lng)
        toggleModal()
    }

    return (
      <div className="group relative 3xs:ml-2">
        <button className="flex items-center text-white relative" onClick={toggleModal}>
            {!isMobile && <img src={earth} className="mr-[8px] relative" alt="lang" />}
          <span className="text-base font-normal">{t('lang')}</span>
            <img src={lang} className="ml-[2px] relative" alt="lang" />
        </button>
        {openModal && (
          <ul className="absolute top-10 3xs:left-[-26px] z-10 cursor-pointer">
            <li className="btn-blue" onClick={() => onChange('ru')}>
              <>Русский</>
            </li>
            <li className="btn-blue" onClick={() => onChange('en')}>
              <>English</>
            </li>
          </ul>
        )}
      </div>
    );
};


