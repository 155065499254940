import React, {useMemo, useRef, useState} from 'react';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import '../../../index.css'
import {Field, Form, Formik, useFormik} from "formik";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {validateEmail, validationSchema} from "./schema";
import {isValidPhoneNumber} from "react-phone-number-input";
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import {useMatchMedia} from "../../../hooks/useMatchMedia";
import {useDispatch, useSelector} from "react-redux";
import {asyncForm} from "../../../store/asyncAction";
import {toast} from "react-toastify";
import {setForm} from "../../../store/searchSlice";

const borderStyle = {
    width: "290px",
    height: "40px",
    border: "1px solid black",
    borderRadius: "5px",
    marginLeft:"5px",
    marginBottom:"15px"
};


const buttonStyle = {
    width: "50px",
    height: "40px",
    border: "1px solid black",
};

const customDropdown = {
    marginRight: "20px"
}

const customLabel = {
    color: "red"
}

const dropdownStyle = {
    color: "red",
    width: "20px"
}

const HomeForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const [error, setError] = useState(false)
    const phoneUtil = PhoneNumberUtil.getInstance();
    const { isMobile, isTablet, isDesktop } = useMatchMedia();
    const response = useSelector((state) => state.search.form)
    const phoneInputRef = useRef(null);
    const [value, setValue ] = useState()
    const successRu = "Данные успешно отправлены"
    const successEn = "Data successfully submitted"


    return (
        <div className="w-[421px] h-[548px] p-[23px] bg-[#FFFFFF33] 2lg:hidden" >
            <Formik
                initialValues={ {
                    // phone: "",
                    email: "",
                    organization: "",
                    description: "",
                    agree: false
                }}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    if (values && value) {
                    dispatch(setForm(value))
                    try {
                        dispatch(asyncForm({values, value, success:successRu }))
                        dispatch(setForm("996"))
                        resetForm()

                        //     const phoneNumber = phoneUtil.parse(values.phone);
                        //     const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
                        //     if (phoneUtil.isValidNumber(phoneNumber)) {
                        //         console.log(`The phone number (${phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)}) is valid for country ${countryCode}.`);
                        //     } else {
                        //         console.log('The phone number is not valid.');
                        //         setError(true);
                        //     }
                    } catch (error) {
                        toast.error('Не удалось загрузить');
                        console.log('Invalid phone number format.');
                        // setError(true);
                    }} else {
                        toast.error("Повторите попытку")
                    }
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form className="w-[375px] h-[502px] bg-white px-[39px] py-[22px]">
                        <div className="mb-[16px] text-center text-lg font-bold leading-[21px]">{t('become')}</div>
                        <div className="input-wrap">
                            <label htmlFor="organization" className="input-label">
                                {t("organization")}</label>
                            <Field
                                name="organization"
                                type="text"
                                className="input"
                                placeholder={t("organization")}
                                required
                            />
                        </div>
                        <div className="input-wrap">
                            { error === false && <label htmlFor="number" className="input-label">
                                {t("phone")}</label>}
                            { error === true && <label htmlFor="number" className="error-label">
                                {t("error")}</label>}
                            <PhoneInput
                                ref={phoneInputRef}
                                name="phone"
                                inputStyle={borderStyle}
                                buttonStyle={buttonStyle}
                                id="contactInput"
                                country={"kg"}
                                value={response}
                                onChange={(value) => setValue( value)}
                            />
                        </div>
                        <div className="input-wrap ">
                            <label htmlFor="email" className="input-label pt-[6px]">
                                {t("email")}</label>
                            <Field
                                name="email"
                                type="email"
                                className="input"
                                placeholder="mail@example.com"
                                validate={validateEmail()}
                                required/>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="description"
                                   className="input-label"> {t("description")}</label>
                            <Field
                                name="description"
                                placeholder={t("description")}
                                type="text"
                                className="input"
                                required
                            />
                        </div>
                        <div className="flex items-start align-middle mb-[18px] mt-4">
                            <div className="flex items-center">
                                <Field
                                    required
                                    name="agree"
                                    id="terms"
                                    type="checkbox"
                                    className="w-[20px] h-[20px] border mr-[10px] border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                />
                            </div>
                            <label htmlFor="agree" className="text-xs leading-[12px] font-normal h-[20px] flex items-center">
                                {t("term")}
                            </label>
                        </div>
                        <button type="submit"
                                className="w-[295px] rounded-[4px] h-[46px] bg-blue text-white text-center">
                            {t("send")}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default HomeForm;