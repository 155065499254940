import analitic from "../../assets/image/resources/Icon (1).svg"
import invest from "../../assets/image/resources/Icon (2).svg"
import base from "../../assets/image/resources/Vector.svg"
import network from "../../assets/image/resources/Icon (3).svg"
import progress from "../../assets/image/resources/Icon (4).svg"
import grant from "../../assets/image/resources/Icon (6).svg"
import expert from "../../assets/image/resources/star.svg"
import member from "../../assets/image/resources/Icon (5).svg"


export const data = [
  { id: 1, title: 'analytic', url: '', img: analitic, link: '/analytics' },
  { id: 0, title: 'investigate', url: '', img: invest, link: '/investigate' },
  { id: 2, title: 'data', url: 'h', img: base, link: '/database' },
  { id: 3, title: 'network', url: '', img: network, link: '/network' },
  { id: 0, title: 'progress', url: '', img: progress, link: '/progress' },
  { id: 1, title: 'aqca', url: '', img: member, link: '/aqca' },
  { id: 2, title: 'grants', url: '', img: grant, link: '/grants' },
  { id: 3, title: 'expert', url: '', img: progress, link: '/expert_country' },
];
export const data2 = [
  { id: 0, title: 'progress', url: '', img: progress, link: '/progress' },
  { id: 1, title: 'aqca', url: '', img: member, link: '/aqca' },
  { id: 2, title: 'grants', url: '', img: grant, link: '/grants' },
  { id: 3, title: 'expert', url: '', img: progress, link: '/expert_country' },
];