 import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Пароль обязательный')
        .min(6, 'Небезопасный пароль'),
    phone: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Пароль не совпадает')
        .required('Подтверждение обязательный'),
});

export const validateEmail = (value) => {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }
    return error;
}