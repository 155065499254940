import React, {useEffect, useRef, useState} from 'react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import '../../../index.css';
import main from "../../../assets/image/home/banner.png"
import Slider from "./slider";
import HomeForm from "./homeForm";

const Banner = () => {
  // const { isLoading  } = useFetch(base + mainUrl + '/slider/');
  // const [response, setResponse] = useState([])
  // const {t} = useTranslation()
  //
  // const getSliders = async () => {
  //     const res = await axios.get(base + mainUrl + '/slider/')
  //     setResponse(res.data)
  // }
  //
  // useEffect(() => {
  //     getSliders()
  // },[])
  //
  // if (isLoading) {
  //     return (
  //         <div role="status" className='flex justify-center my-28 pb-24'>
  //             <ClipLoader
  //                 color="#1985A1"
  //                 size={300}
  //             />
  //         </div>
  //     )
  // }

  return (
    <div
      className="relative flex justify-between items-center w-full h-[672px] 3sm:h-[510px] m-auto bg-banner"
      style={{
        backgroundImage: `url(${main})`,
      }}
    >
      <div className="wrapper">
        <Slider />
        <HomeForm />
        {/* <div className="hidden 2lg:block">
          <MobileForm />
        </div> */}
      </div>
    </div>
  );
};

export default Banner;