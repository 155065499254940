import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useFetch} from "../../api/useFetch";
import {api, main, base, url} from "../../api/const";
import date from "../../assets/image/home/clock.svg"
import location from "../../assets/image/home/Icon (2).svg"
import {BreadCrumb} from "../../components/general/breadcrumb";
import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {ClipLoader} from "react-spinners";
import {Sanitized} from "../../components/general/sanitize";


export const ResearchPage = () => {
  const { isLoading } = useFetch(main + 'get-decisions/');
  const { t, i18n } = useTranslation();
  const [response, setResponse] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const limit = 6;
  window.scrollTo(0, 0);

  const getData = async () => {
    const res = await fetch(main + 'get-decisions/');
    const data = await res.json();
    setpageCount(Math.ceil(data.count / limit));
    setResponse(data);
  };

  useEffect(() => {
    getData();
  }, [limit]);

  const paginateData = async (count) => {
    const res = await fetch(main + `get-decisions/?page=${count}`);
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    if (data.selected > 0) {
      let currentPage = data.selected + 1;
      const paginateServer = await paginateData(currentPage);
      setResponse(paginateServer);
    } else {
      const paginateServer = await paginateData(1);
      setResponse(paginateServer);
    }
  };

  if (isLoading) {
    return (
      <div role="status" className="flex justify-center my-28 pb-24">
        <ClipLoader color="#1985A1" size={300} />
      </div>
    );
  }

  return (
    <div className="wrapper h-auto py-[62px]">
      <div>
        <div className="title">{t('investigate')}</div>
        <BreadCrumb />
      </div>
      <div className="flex justify-center flex-wrap wrapper">
        {' '}
        {response &&
          response.map((item) => (
            <Link
              to={`/investigate/${item.id}`}
              className="block mb-8 w-[384px] h-[390px] m-auto shadow-xl rounded bg-white pb-4 leading-5 cursor-pointer hover:shadow-2xl 3lg:w-[384px] xl:w-[340px] 1sm:w-[384px] xs:w-[300px] xs:h-[304px]"
            >
              <div className="mb-3 h-[217px] overflow-hidden rounded-t xl:w-[340px] 2lg:w-[340px] 1sm:w-full xs:w-[300px] 1xs:h-[220px] xs:h-[160px] xs:mb-0 3lg:w-full 2lg:w-full">
                <img
                  src={url + item.decision_slider_image[0].image}
                  alt="cart-img"
                  className="h-auto w-[100%] rounded-t"
                />
              </div>
              <div className="px-2.5">
                <>
                  <p className="cart-title mb-2">
                    {item[`title_${i18n.language}`].length > 60
                      ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                      : item[`title_${i18n.language}`]}
                  </p>
                  <p className="solution-content">
                    <Sanitized html={item[`short_desc_${i18n.language}`]} />
                  </p>
                  <div className="flex flex-wrap mt-[12px] 2md:text-[12px] 1xs:mt-[8px]">
                    {item.date && (
                      <div className="flex">
                        <img src={date} alt="clock" className="cart-icon" />
                        <p className="text-sm font-medium 3sm:text-[12px]">
                          {item.date.split('-').reverse().join('.')}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </Link>
          ))}
      </div>
      <div className="paginate wrapper justify-end flex">
        <ReactPaginate
          nextLabel="❯"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="❮"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};