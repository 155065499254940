 import DOMPurify from "dompurify";
 import React from "react";
 import {useTranslation} from "react-i18next";
 import {allowTags} from "../../api/const";

 export const CustomDompurify = (html) => {

     DOMPurify.addHook('afterSanitizeAttributes', (node) => {
         if (node.tagName === 'A') {
             node.classList.add('links');
         }
         if (node.nodeName === 'A') {
             node.setAttribute('target', '_blank');
         }
         // if (node.nodeName === 'SPAN' && node.style.color === '#3498db') {
         //     node.nodeValue = node.nodeValue.replace(/\r\n\r\n/g, '\n');
         // }

         if ('src' in node) {
             node.setAttribute('class', 'mx-[10px] my-[5px]');
         }
         if (node.nodeName.toLowerCase() === '') {
             const originalHref = node.getAttribute('href');
             if (originalHref.startsWith('mailto:')) {
                 const newHref = 'https://mail.google.com/mail/?view=cm&to=' + originalHref.substring(7);
                 node.setAttribute('href', newHref);
             }
         }
     });

        return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html.html,{ALLOWED_TAGS: allowTags, allowedAttributes: {
                    a: [ 'href', 'alt', 'target', 'rel', 'class', 'id', 'style', 'src' ],
                    img: ['src', 'alt', 'width', 'height'],
                    iframe: ['src', 'width', 'height'],
                },
                ADD_ATTR: ['target'] ,
                ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp|xxx):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i
        })}} ></div>
 };