import React from 'react';
import googleplay from "../../assets/image/home/googleplay.svg"
import appStore from "../../assets/image/home/appstore.svg"
import {useTranslation} from "react-i18next";

const Advertisement = () => {
    const {t} = useTranslation()

    return (
        <div className="w-full min-h-[131px] bg-gradient-advertisement flex items-center 2lg:py-6">
            <div className="wrapper flex justify-around ">
                <div className="max-w-[662px] text-white px-1">
                    <div className="text-[22px] font-base mb-3 1md:text-[20px] 1xs:text-[18px]">
                        {t('donwload')}
                    </div>
                    <div className="font-normal text-lg leading-[24px] 1xs:text-[16px]">
                        {t('downDescrip')}
                    </div>
                </div>
                <div
                    className="flex flex-wrap justify-between 4xs:justify-around items-center min-w-[408px] 2lg:block 2lg:min-w-[183px] sm:flex sm:min-w-[400px] xs:min-w-[320px] 4xs:min-w-[280px] sm:mt-2 xs:wrapper">
                    <img
                        src={appStore}
                        alt="values"
                        className="w-[183px] h-[53px] cursor-pointer 2lg:mb-2 sm:mb-0 xs:w-[150px] 4xs:w-[120px]"
                        onClick={() =>
                            window.open(
                                'https://apps.apple.com/ru/app/%D0%B2%D0%BE%D0%B7%D0%B4%D1%83%D1%85-%D0%B0%D0%B7%D0%B8%D1%8F/id1585485283'
                            )
                        }
                    />
                    <img
                        src={googleplay}
                        alt="values"
                        className="w-[183px] h-[53px] cursor-pointer xs:w-[150px] 4xs:w-[120px]"
                        onClick={() =>
                            window.open(
                                'https://play.google.com/store/apps/details?id=kg.movegreen.aqca'
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Advertisement;