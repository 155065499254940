import React, {useEffect, useState} from 'react';
import {useFetch} from "../../api/useFetch";
import {aboutUrl, base, lead, newsUrl, resources, url} from "../../api/const";
import {BreadCrumbs} from "../../components/modules/breadcrumbs";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Sanitized} from "../../components/general/sanitize";
import {ClipLoader} from "react-spinners";
import ShareSocial from "../../components/general/share-social";
import {ImagesSlider} from "../../components/general/photoSlider";
import date from "../../assets/image/home/clock.svg"
import DOMPurify from "dompurify";
import SimuliarAnalytics from "./simuliarAnalytics";
import {CustomDompurify} from "../../components/general/dompurify";


const DetailAnalytic = () => {
    window.scroll(0,0)
    const {id} = useParams()
    const { isLoading, response } = useFetch(resources + `analytics-info/${id}/`);
    const {t, i18n} = useTranslation()

    const crumb = [
        t("main"),
        '/',
        t("solution"),
        '/',
    ]

    const [crumbs, setCrumbs] = useState(crumb);
    const crumbSet = () => {
        setCrumbs(crumb)
    }
    useEffect(() => {
        crumbSet()
    }, [i18n.language]);


    if (isLoading) {
        return (
            <div role="status" className='flex justify-center my-28 pb-24'>
                <ClipLoader
                    color="#1985A1"
                    size={300}
                />
            </div>
        )
    }

    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
        if (node.tagName === 'A') {
            node.classList.add('links');
        }
        if (node.tagName === 'P') {
            node.classList.add('space');
        }
    });


    return (
        <div className="wrapper w-full font-general relative py-[63px] text-justify">
            {response && (
                <>
                    <div className="">
                        <div>
                            <div className="title">{t('solution')}</div>
                            <BreadCrumbs crumbs={crumbs} title={response[`title_${i18n.language}`]} />
                        </div>
                        <>
                            <p className="mb-8 font-bold text-base lg:text-[18px] 3md:text-[16px] sm:mt-2">
                                {response[`title_${i18n.language}`]}
                            </p>
                            <div className="max-w-[432px] h-auto mr-[32px] mb-2 z-10 overflow-hidden float-left sm:float-none sm:max-w-[432px] sm:m-auto 1xs:max-w-[320px] xl:mr-[32px]">
                                <ImagesSlider images={response.analytics_slider_image} />
                            </div>
                            <div className="text-content">
                            <CustomDompurify html={response[`text_${i18n.language}`]}/>
                            </div>
                        </>
                    </div>
                </>
            )}
            <div className="w-full flex justify-between flex-wrap mt-4 mx-2">
                <div className="flex">
                    <p className="text-lg text-black font-medium mr-2">{t('share')}</p>
                    <ShareSocial />
                </div>
                <div className="flex mt-[0px] max-w-[405px] 2md:text-[12px]">
                    {response && (
                        <div className="flex">
                            <img
                                src={date}
                                alt="clock"
                                className="detail-icon"
                            />
                            <p className="text-lg text-black font-medium">
                                {response.date.split('-').reverse().join('.')}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <SimuliarAnalytics/>
        </div>
    );
};

export default DetailAnalytic;