import React, {useEffect} from 'react';
import {BreadCrumb} from "../components/general/breadcrumb";
import ClipLoader from "react-spinners/ClipLoader";
import {Sanitized} from "../components/general/sanitize";
import LinkResource from "../components/linkResource";
import {useTranslation} from "react-i18next";
import {useFetch} from "../api/useFetch";
import {allowTags, newsUrl, resources, uri} from "../api/const";
import {CreateMarkup, CustomDompurify} from "../components/general/dompurify";
import DOMPurify from "dompurify";

const AboutPage = () => {
    const { isLoading, response } = useFetch(resources + 'about-info/');
    const {t,i18n} = useTranslation()
    window.scrollTo(0, 0);

    if (isLoading) {
        return (
            <div role="status" className='flex justify-center my-28 pb-24'>
                <ClipLoader
                    color="#1985A1"
                    size={300}
                />
            </div>
        )
    }


        // DOMPurify.addHook('afterSanitizeAttributes', (node) => {
        //     if (node.tagName === 'A') {
        //         node.classList.add('links');
        //     }
        //     if (node.nodeName === 'SPAN' && node.style.color === '#3498db') {
        //         node.nodeValue = node.nodeValue.replace(/\r\n\r\n/g, '\n');
        //     }
        // });



    return (
        <div className="wrapper min-h-[50vh] py-[62px] 2md:py-[32px]">
            <div className="w-full">
                <div className="title">{t('about')}</div>
                <BreadCrumb/>
            </div>
            {response &&
                <div>
                    <div
                        className="flex max-w-[1236px] m-auto rounded bg-white mb-10 items-center">
                            <div className="text-content">
                                <CustomDompurify html={response[`data_${i18n.language}`].replace(/\r\n\r\n/g, "<br/>")}/>
                            </div>
                    </div>
                        {/*<div>*/}
                        {/*    <div className="title-link">{t('resource-link')}</div>*/}
                        {/*    <div>*/}
                        {/*        {response.about_us_links.map(item => (*/}
                        {/*            <div className="text-link" onClick={() => window.open(item.link)} key={item.id}>{item.link}</div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                </div>
            }
        </div>
    );
};

export default AboutPage;