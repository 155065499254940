import React from "react";
import {ClipLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {main,url, limitUrl, mainUrl} from "../../../api/const";
import {useFetch} from "../../../api/useFetch";
import ReactPaginate from "react-paginate";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useMatchMedia} from "../../../hooks/useMatchMedia";
import Social from "../../general/social";
import ShareSocial from "../../general/share-social";
import date from "../../../assets/image/home/clock.svg"
import {Sanitized} from "../../general/sanitize";

export const NewsHome = () => {
    const {t, i18n} = useTranslation()
    const [response, setResponse] = useState([])
    const [pageCount, setpageCount] = useState(0);
    const {isMobile, isTablet, isDesctop} = useMatchMedia();
    const limit = 4

    const getData = async () => {
        const res = await fetch(main + 'get-news/');
        const data = await res.json();
        setpageCount(Math.ceil(data.count / limit));
        setResponse(data);
    }

    useEffect(() => {
        getData()
    },[])

    const paginateData = async (count) => {
        const res = await fetch(main + `get-news/?page=${count}`);
        const data = await res.json();
        return data;
    };

    console.log(response)

    const handlePageClick = async (data) => {
        if( data.selected > 0 ){
            let currentPage = data.selected + 1;
            const paginateServer = await paginateData(currentPage);
            setResponse(paginateServer);
        } else {
            const paginateServer = await paginateData(1);
            setResponse(paginateServer);
        }
    };


    return (
      <div className="h-auto font-inter">
        <div className="flex wrapper justify-between mb-[33px]">
          <p className="title ml-2">{t('analytic')}</p>
          <Link to="/analytics" className="all-view mr-2">
            {t('all')}
          </Link>
        </div>
        {response.results &&
          response.results.map((item) => (
            <Link to={`/analytics/${item.id}`} className="" key={item.id}>
              <div className="max-w-[715px] max-h-[178px] xs:max-h-[140px] 1xs:max-h-[170px] shadow-xl rounded px-[22px] py-[22px] 3xs:px-[14px] 3xs:py-[12px] 2xs:px-[8px] 2xs:py-[8px] bg-white mb-8 hover:shadow-2xl m-auto">
                {isMobile && (
                  <p className="mobile-title">
                    <Sanitized
                      html={item[`title_${i18n.language}`].slice(0, 60)}
                    />
                  </p>
                )}
                <div className="flex justify-between">
                  <div className="w-[405px] 1xs:w-[250px] xs:w-[405px] h-auto 1xs:mt-1.5 2xs:w-[210px]">
                    {!isMobile && (
                      <p className="cart-title">
                        <Sanitized
                          html={item[`title_${i18n.language}`].slice(0, 60)}
                        />
                      </p>
                    )}
                    <p className="cart-content">
                      <Sanitized html={item[`short_desc_${i18n.language}`]} />
                    </p>
                    <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                      {item.date && (
                        <div className="flex">
                          <img src={date} alt="clock" className="cart-icon" />
                          <p className="text-sm font-medium 3sm:text-[12px]">
                            {item.date.split('-').reverse().join('.')}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="m-auto w-[244px] h-[134px] 1xs:w-[200px] 1xs:h-[100px] 2xs:w-[100px] overflow-hidden ml-[32px] 3lg:ml-[12px]">
                    <img
                      src={url + item.news_slider_image[0].image}
                      alt="values"
                      className="h-auto w-[100%] rounded-[2px]"
                    />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        <div className="paginate wrapper m-auto lg:mb-8">
          <ReactPaginate
            nextLabel="❯"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="❮"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    );
};

export default NewsHome;



// <div className="flex justify-between">
//     <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
//
//
//
//     </div>
//
// </div>

