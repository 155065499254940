import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";
import {Sanitized} from "../components/general/sanitize";
import date from "../assets/image/home/clock.svg";
import {month, url} from "../api/const";
import {Link} from "react-router-dom";
import {useMatchMedia} from "../hooks/useMatchMedia";

const SearchPage = () => {
    const { t, i18n } = useTranslation();
    const items = useSelector((state) => state.search.data);
    const isEmpty = useSelector((state) => state.search.isEmpty);
    const { isMobile, isTablet, isDesktop } = useMatchMedia();

    if (!items) {
        return (
            <div role="status" className="flex justify-center my-28 pb-24">
                <ClipLoader color="#1985A1" size={300} />
            </div>
        );
    }

    return (
      <div className="containers">
        {isEmpty && (
          <div className="flex w-[1236px] m-auto shadow-md rounded px-8 my-8 bg-white">
            <p className="my-16 text-[20px]">{t('notFound')}</p>
          </div>
        )}
        {items && (
          <>
            {items.news && items.news.length > 0 && (
              <p className="font-medium my-4 w-full">
                {t('analytic')}
                <span className="ml-1">({items.news.length})</span>
              </p>
            )}
            <div className="wrapper justify-between flex-wrap 2md:justify-center">
              {items.news &&
                items.news.map((item, idx) => (
                  <Link
                    to={`/analytics/${item.id}`}
                    className="card-container"
                    key={item.id}
                  >
                    {isMobile && (
                      <p className="mobile-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}
                    <div className="flex justify-between">
                      <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
                        {!isMobile && (
                          <p className="cart-title">
                            {item[`title_${i18n.language}`].length > 60
                              ? item[`title_${i18n.language}`].slice(0, 60) +
                                '...'
                              : item[`title_${i18n.language}`]}
                          </p>
                        )}
                        <p className="cart-content max-h-[60px] xs:h-[60px]">
                          <Sanitized
                            html={item[`short_desc_${i18n.language}`]}
                          />
                        </p>
                        <div className="flex flex-wrap mt-[18px] xs:mt-[4px] max-w-[405px] 2md:text-[12px]">
                          <div className="flex">
                            <img src={date} alt="clock" className="cart-icon" />
                            <p className="text-sm font-medium 3sm:text-[12px]">
                              {item.date.split('-').reverse().join('.')}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="m-auto w-[284px] h-[134px] 1xs:h-[100px] xs:h-[80px] overflow-hidden">
                        <img
                          src={url + item.news_slider_image[0].image}
                          alt="values"
                          className="h-auto w-[100%] ml-[32px] xl:ml-[10px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              {items.events && items.events.length > 0 && (
                <p className="font-medium my-4 w-full">
                  {t('events')}
                  <span className="ml-1">({items.events.length})</span>
                </p>
              )}
              {items.events &&
                items.events.map((item, idx) => (
                  <Link
                    to={`/events/${item.id}`}
                    className="card-container "
                    key={item.id}
                  >
                    {isMobile && (
                      <p className="mobile-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}
                    <div className="flex justify-between">
                      <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
                        {!isMobile && (
                          <p className="cart-title">
                            {item[`title_${i18n.language}`].length > 60
                              ? item[`title_${i18n.language}`].slice(0, 60) +
                                '...'
                              : item[`title_${i18n.language}`]}
                          </p>
                        )}

                        <p className="cart-content max-h-[60px] xs:h-[60px]">
                          <Sanitized
                            html={item[`short_desc_${i18n.language}`]}
                          />
                        </p>
                        <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                          {item.date && (
                            <div className="flex">
                              <img
                                src={date}
                                alt="clock"
                                className="cart-icon"
                              />
                              <p className="text-sm font-medium 3sm:text-[12px]">
                                {item.date.split('-').reverse().join('.')}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-wrap mt-[18px] max-w-[405px] 2md:text-[12px]">
                          <div className="flex">
                            <img src={date} alt="clock" className="cart-icon" />
                            <p className="text-sm font-medium 3sm:text-[12px]">
                              {item.date_start.split('-').slice(2).join()}-
                              {item.date_end.split('-').slice(2).join()}{' '}
                              {t(
                                `${
                                  month[
                                    item.date_end.split('-').slice(1, 2).join()
                                  ]
                                }`
                              )}{' '}
                              {item.date_end.split('-').slice(0, 1).join()}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="m-auto w-[284px] h-[134px]  overflow-hidden 1xs:h-[100px]">
                        <img
                          src={url + item.events_slider_image[0].image}
                          alt="values"
                          className="h-auto w-[100%] ml-[32px] xl:ml-[10px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              {items.decisions && items.decisions.length > 0 && (
                <p className="font-medium my-4 w-full">
                  {t('investigate')}
                  <span className="ml-1">({items.decisions.length})</span>
                </p>
              )}
              {items.decisions &&
                items.decisions.map((item, idx) => (
                  <Link
                    to={`/investigate/${item.id}`}
                    className="card-container"
                    key={item.id}
                  >
                    {isMobile && (
                      <p className="mobile-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}
                    <div className="flex justify-between">
                      <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
                        {!isMobile && (
                          <p className="cart-title">
                            {item[`title_${i18n.language}`].length > 60
                              ? item[`title_${i18n.language}`].slice(0, 60) +
                                '...'
                              : item[`title_${i18n.language}`]}
                          </p>
                        )}

                        <p className="search-content">
                          <Sanitized html={item[`text_${i18n.language}`]} />
                        </p>
                        <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                          {item.date && (
                            <div className="flex">
                              <img
                                src={date}
                                alt="clock"
                                className="cart-icon"
                              />
                              <p className="text-sm font-medium 3sm:text-[12px]">
                                {item.date.split('-').reverse().join('.')}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="m-auto w-[284px] h-[134px] 1xs:h-[100px] xs:h-[80px] overflow-hidden">
                        <img
                          src={url + item.decision_slider_image[0].image}
                          alt="values"
                          className="h-auto w-[100%] ml-[32px] xl:ml-[10px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              {items.analytics && items.analytics.length > 0 && (
                <p className="font-medium my-4 w-full">
                  {t('news')}
                  <span className="ml-1">({items.analytics.length})</span>
                </p>
              )}
              {items.analytics &&
                items.analytics.map((item, idx) => (
                  <Link
                    to={`/solutions/${item.id}`}
                    className="card-container"
                    key={item.id}
                  >
                    {isMobile && (
                      <p className="mobile-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}
                    <div className="flex justify-between">
                      <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
                        {!isMobile && (
                          <p className="cart-title">
                            {item[`title_${i18n.language}`].length > 60
                              ? item[`title_${i18n.language}`].slice(0, 60) +
                                '...'
                              : item[`title_${i18n.language}`]}
                          </p>
                        )}

                        <p className="search-content">
                          <Sanitized
                            html={item[`short_desc_${i18n.language}`]}
                          />
                        </p>
                        <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                          {item.date && (
                            <div className="flex">
                              <img
                                src={date}
                                alt="clock"
                                className="cart-icon"
                              />
                              <p className="text-sm font-medium 3sm:text-[12px]">
                                {item.date.split('-').reverse().join('.')}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="m-auto w-[284px] h-[134px] 1xs:h-[100px] xs:h-[80px] overflow-hidden">
                        <img
                          src={url + item.analytics_slider_image[0].image}
                          alt="values"
                          className="h-auto w-[100%] ml-[32px] xl:ml-[10px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              {items.research && items.research.length > 0 && (
                <p className="font-medium my-4 w-full">
                  {t('news')}
                  <span className="ml-1">({items.research.length})</span>
                </p>
              )}
              {items.research &&
                items.research.map((item, idx) => (
                  <Link
                    to={`/news/${item.id}`}
                    className="card-container"
                    key={item.id}
                  >
                    {isMobile && (
                      <p className="mobile-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}
                    <div className="flex justify-between">
                      <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
                        {!isMobile && (
                          <p className="cart-title">
                            {item[`title_${i18n.language}`].length > 60
                              ? item[`title_${i18n.language}`].slice(0, 60) +
                                '...'
                              : item[`title_${i18n.language}`]}
                          </p>
                        )}

                        <p className="search-content">
                          <Sanitized
                            html={item[`short_desc_${i18n.language}`]}
                          />
                        </p>
                        <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                          {item.date && (
                            <div className="flex">
                              <img
                                src={date}
                                alt="clock"
                                className="cart-icon"
                              />
                              <p className="text-sm font-medium 3sm:text-[12px]">
                                {item.date.split('-').reverse().join('.')}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="m-auto w-[284px] h-[134px] 1xs:h-[100px] xs:h-[80px] overflow-hidden">
                        <img
                          src={url + item.research_slider_image[0].image}
                          alt="values"
                          className="h-auto w-[100%] ml-[32px] xl:ml-[10px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              {items.grants && items.grants.length > 0 && (
                <p className="font-medium my-4 w-full">
                  {t('grants')}
                  <span className="ml-1">({items.grants.length})</span>
                </p>
              )}
              {items.grants &&
                items.grants.map((item, idx) => (
                  <Link
                    to={`/grants/${item.id}`}
                    className="card-container"
                    key={item.id}
                  >
                    {isMobile && (
                      <p className="mobile-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}
                    <div className="flex justify-between">
                      <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
                        {!isMobile && (
                          <p className="cart-title">
                            {item[`title_${i18n.language}`].length > 60
                              ? item[`title_${i18n.language}`].slice(0, 60) +
                                '...'
                              : item[`title_${i18n.language}`]}
                          </p>
                        )}

                        <p className="search-content">
                          <Sanitized
                            html={item[`short_desc_${i18n.language}`]}
                          />
                        </p>
                        <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                          {item.date && (
                            <div className="flex">
                              <img
                                src={date}
                                alt="clock"
                                className="cart-icon"
                              />
                              <p className="text-sm font-medium 3sm:text-[12px]">
                                {item.date.split('-').reverse().join('.')}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="m-auto w-[284px] h-[134px] 1xs:h-[100px] xs:h-[80px] overflow-hidden">
                        <img
                          src={url + item.grants_slider_image[0].image}
                          alt="values"
                          className="h-auto w-[100%] ml-[32px] xl:ml-[10px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              {items.expert && items.expert.length > 0 && (
                <p className="font-medium my-4 w-full">
                  {t('expert')}
                  <span className="ml-1">({items.expert.length})</span>
                </p>
              )}
              {items.expert &&
                items.expert.map((item, idx) => (
                  <Link
                    to={`/expert/${item.id}`}
                    className="card-container"
                    key={item.id}
                  >
                    {isMobile && (
                      <p className="mobile-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}
                    <div className="flex justify-between">
                      <div className="ml-4 2sm:ml-2 w-[405px] 1xs:mt-1.5">
                        {!isMobile && (
                          <p className="cart-title">
                            {item[`title_${i18n.language}`].length > 60
                              ? item[`title_${i18n.language}`].slice(0, 60) +
                                '...'
                              : item[`title_${i18n.language}`]}
                          </p>
                        )}

                        <p className="search-content">
                          <Sanitized
                            html={item[`short_desc_${i18n.language}`]}
                          />
                        </p>
                        <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                          {item.date && (
                            <div className="flex">
                              <img
                                src={date}
                                alt="clock"
                                className="cart-icon"
                              />
                              <p className="text-sm font-medium 3sm:text-[12px]">
                                {item.date.split('-').reverse().join('.')}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="m-auto w-[284px] h-[134px] 1xs:h-[100px] xs:h-[80px] overflow-hidden">
                        <img
                          src={url + item.expert_slider_image[0].image}
                          alt="values"
                          className="h-auto w-[100%] ml-[32px] xl:ml-[10px] rounded-[2px]"
                        />
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </>
        )}
      </div>
    );
};

export default SearchPage;




