import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../components/general/breadcrumb";
import {useFetch} from "../../api/useFetch";
import {events, url, main, newsUrl, uri, month, resources} from "../../api/const";
import {ClipLoader} from "react-spinners";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import {Sanitized} from "../../components/general/sanitize";
import date from "../../assets/image/home/clock.svg"
import {useMatchMedia} from "../../hooks/useMatchMedia";

const SimuliarAnalytics = () => {
    const {t, i18n} = useTranslation()
    const [response, setResponse] = useState([])
    const [pageCount, setpageCount] = useState(0);
    const { isMobile, isTablet, isDesktop } = useMatchMedia();
    const limit = 4

    const getData = async () => {
        const res = await fetch(resources + 'last-analytics-info/');
        const data = await res.json();
        setpageCount(Math.ceil(data.count / limit));
        setResponse(data);
        console.log(data)
    }

    useEffect(() => {
        getData()
    },[limit])

    const paginateData = async (count) => {
        const res = await fetch(resources + `last-analytics-info/?page=${count}`);
        const data = await res.json();
        return data;
    };

    console.log(response)

    const handlePageClick = async (data) => {
        if( data.selected > 0 ){
            let currentPage = data.selected + 1;
            const paginateServer = await paginateData(currentPage);
            setResponse(paginateServer);
        } else {
            const paginateServer = await paginateData(1);
            setResponse(paginateServer);
        }
    };


    return (
      <div className="simuliar_containers">
        <>
          <div className="title">{t('similarSolution')}</div>
        </>
        <div className="wrapper justify-between flex-wrap 2md:justify-center">
          {response.results &&
            response.results.map((item) => (
              <Link
                to={`/solutions/${item.id}`}
                className="card-container"
                key={item.id}
              >
                {isMobile && (
                  <p className="mobile-title">
                    {item[`title_${i18n.language}`].length > 60
                      ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                      : item[`title_${i18n.language}`]}
                  </p>
                )}
                <div className="flex justify-between">
                  <div className="content-wrap">
                    {!isMobile && (
                      <p className="cart-title">
                        {item[`title_${i18n.language}`].length > 60
                          ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                          : item[`title_${i18n.language}`]}
                      </p>
                    )}

                    <p className="cart-content">
                      <Sanitized html={item[`short_desc_${i18n.language}`]} />
                    </p>

                    <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                      <div className="flex">
                        <img src={date} alt="clock" className="cart-icon" />

                        <p className="text-sm font-medium 3sm:text-[12px]l">
                          {item.date.split('-').reverse().join('.')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="image-container">
                    <img
                      src={url + item.analytics_slider_image[0].image}
                      alt="values"
                      className="img-inside"
                    />
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <div className="paginate wrapper justify-end flex">
          {response.results && response.results.length >= 4 && (
            <ReactPaginate
              nextLabel="❯"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="❮"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          )}
        </div>
      </div>
    );
};

export default SimuliarAnalytics;