import React, {useMemo, useRef, useState} from 'react';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import '../../../index.css'
import {Field, Form, Formik, useFormik} from "formik";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {isValidPhoneNumber} from "react-phone-number-input";
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import {validateEmail} from "../../main/Banner/schema";
import {useMatchMedia} from "../../../hooks/useMatchMedia";
import {useDispatch, useSelector} from "react-redux";
import {setForm} from "../../../store/searchSlice";
import {asyncForm} from "../../../store/asyncAction";
import {toast} from "react-toastify";

const borderStyle = {
    width: "585px",
    height: "40px",
    border: "1px solid black",
    borderRadius: "5px",

};

const tabletStyle = {
    width: "320px",
    height: "40px",
    border: "1px solid black",
    borderRadius: "5px",

};


const buttonStyle = {
    width: "50px",
    height: "40px",
    border: "1px solid black",
};

const AqcaForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {i18n, t} = useTranslation();
    const [error, setError] = useState(false)
    const phoneUtil = PhoneNumberUtil.getInstance();
    const { isMobile, isTablet, isDesktop } = useMatchMedia();
    const response = useSelector((state) => state.search.form)
    const phoneInputRef = useRef(null);
    const [value, setValue ] = useState()
    const successRu = "Данные успешно отправлены"
    const successEn = "Data successfully submitted"

    return (
        <div className="w-full shadow-xl my-[32px]">
            <Formik
                initialValues={{
                    // phone: "",
                    email: "",
                    organization: "",
                    description: "",
                    agree: false
                }}
                onSubmit={(values, {resetForm}) => {
                    dispatch(setForm(value))
                    try {
                        if(i18n.language === "ru"){
                            dispatch(asyncForm({values, value, success:successRu }))
                        } else {
                            dispatch(asyncForm({values, value, success:successEn}))
                        }
                        dispatch(setForm("996"))
                        resetForm()


                        //     const phoneNumber = phoneUtil.parse(values.phone);
                        //     const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
                        //     if (phoneUtil.isValidNumber(phoneNumber)) {
                        //         console.log(`The phone number (${phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)}) is valid for country ${countryCode}.`);
                        //     } else {
                        //         console.log('The phone number is not valid.');
                        //         setError(true);
                        //     }
                    } catch (error) {
                        toast.error('Не удалось загрузить');
                        console.log('Invalid phone number format.');
                        // setError(true);
                    }
                }}
            >
                {({ values, setFieldValue }) => (

                    <div className="flex xl:justify-center">
                    <Form className="max-w-[1215px] m-auto py-8">
                        <div className="mb-[20px] text-lg font-bold leading-[21px]">{t("become")}</div>
                        <div className="grid grid-cols-2 xl:grid-cols-1 gap-4 xl:gap-2">
                          <div className="m-auto">
                            <label htmlFor="organization" className="input-label ">
                                {t("organization")}</label>
                            <Field
                                name="organization"
                                type="text"
                                className="aqca-input"
                                placeholder={t("organization")}
                                required
                            />
                        </div>
                          <div className="m-auto">
                            { error === false && <label htmlFor="number" className="input-label">
                                {t("phone")}</label>}
                            { error === true && <label htmlFor="number" className="error-label">
                                {t("error")}</label>}
                              { !isDesktop && (
                                  <PhoneInput
                                      ref={phoneInputRef}
                                      name="phone"
                                      inputStyle={tabletStyle}
                                      buttonStyle={buttonStyle}
                                      id="contactInput"
                                      country={"kg"}
                                      value={response}
                                      onChange={(value) => setValue(value)}
                                  />
                              )}
                              { isDesktop && (
                                  <PhoneInput
                                      ref={phoneInputRef}
                                      name="phone"
                                      inputStyle={borderStyle}
                                      buttonStyle={buttonStyle}
                                      id="contactInput"
                                      country={"kg"}
                                      value={response}
                                      onChange={(value) => setValue(value)}
                                  />
                              )}
                        </div>
                          <div className="m-auto">
                            <label htmlFor="email" className="input-label">
                                {t("email")}</label>
                            <Field
                                name="email"
                                type="email"
                                className="aqca-input"
                                placeholder="mail@example.com"
                                validate={validateEmail()}
                                required/>
                        </div>
                          <div className="m-auto">
                            <label htmlFor="description"
                                   className="input-label"> {t("description")}</label>
                            <Field
                                name="description"
                                placeholder={t("description")}
                                type="text"
                                className="aqca-input"
                                required
                            />
                        </div>
                        </div>
                        <div className="flex justify-start align-middle mb-[22px] mt-[43px]">
                            <div className="flex items-center justify-center">
                                <Field
                                    required
                                    name="agree"
                                    id="terms"
                                    type="checkbox"
                                    className="w-[20px] h-[20px] border mr-[10px] border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                />
                            </div>
                            <label htmlFor="agree" className="text-xs leading-[12px] font-normal h-[20px] flex items-center">
                                {t("term")}
                            </label>
                        </div>
                        <button type="submit"
                                className="w-[260px] md:w-[300px] m-auto rounded-[4px] h-[46px] bg-blue text-white text-center ">
                            {t("send")}
                        </button>
                    </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default AqcaForm;