import React from 'react';
import Banner from '../components/main/Banner/banner';
import NewsHome from '../components/main/newsAndEvents/newsHome';
import { EventsHome } from '../components/main/newsAndEvents/eventsHome';
import Advertisement from '../components/main/advertisement';
import { Solution } from '../components/main/solution/solution';

const MainPage = () => {
    return (
        <div className="m-auto font-inter">
            <Banner/>
            <div className="flex 2lg:block justify-around max-w-[1215px] m-auto py-[62px] xl:max-w-[1136px]
             3lg:max-w-[1090px] 2lg:max-w-[1000px] lg:max-w-[930px] 2md:max-w-[836px] 3md:max-w-[780px]
             1md:max-w-[730px] md:max-w-[716px] sm:max-w-[656px] 2sm:max-w-[600px] 3xs:max-w-[510px] 1sm:max-w-[560px]
             3sm:max-w-[430px] 1xs:max-w-[470px] xs:max-w-[360px] 2xs:max-w-[330px] 4xs:max-w-[280px]">
                <NewsHome/>
                <EventsHome/>
            </div>
            <Advertisement/>
            <Solution/>
        </div>
    );
};

export default MainPage;