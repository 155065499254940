import React, {useState} from 'react';
import KG from "../../assets/image/country/kyrgyzstan_folder_icon_640.png"
import KZ from "../../assets/image/country/kazakhstan_folder_icon_640.png"
import UZ from "../../assets/image/country/uzbekistan_folder_icon_640.png"
import TJ from "../../assets/image/country/tajikistan_folder_icon_640.png"
import {BreadCrumb} from "../../components/general/breadcrumb";
import {useTranslation} from "react-i18next";
import {useMatchMedia} from "../../hooks/useMatchMedia";
import {useNavigate} from "react-router";
import TU from "../../assets/image/country/turkmenistan_folder_icon_640.png";

const ExpertCountry = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    window.scrollTo(0, 0);

    return (
        <div className="containers">
            <div>
                <div className="title">{t('expert')}</div>
                <BreadCrumb/>
            </div>
            <div className=" flex-wrap justify-center w-full">
                <div className="flex justify-center xl:justify-around flex-wrap">
                    <img src={KG} alt="country" className="country"
                         onClick={() => navigate("1")}/>
                    <img src={KZ} alt="country" className="country" onClick={() => navigate("2")}/>
                    <img src={UZ} alt="country" className="country" onClick={() => navigate("3")}/>
                    <img src={TJ} alt="country" className="country" onClick={() => navigate("4")}/>
                    <img src={TU} alt="country" className="country" onClick={() => navigate("5")}/>
                </div>
            </div>
        </div>
    );
};

export default ExpertCountry;