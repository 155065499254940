import './app.css'
import {Route, Routes} from "react-router-dom";
import Menu from "./components/general/menu";
import React from "react";
import {publicRoutes} from "./routes/routes";
import Footer from "./components/general/footer/footer";


document.body.style.overflow = "";

function App() {
    return (
      <div className="m-auto text-black bg-white h-[100%] relative">
        <Menu/>
        <Routes>
          {publicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
          <Footer />
      </div>
    );
}

export default App;