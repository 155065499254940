import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import NetworkInternational from "./networkinternational";
import NetworkNPO from "./networkNPO";
import {BreadCrumbs} from "../../components/modules/breadcrumbs";

const NetworkPage = () => {
    const {t} = useTranslation()
    const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: t('networkNPO'),
            component: <NetworkNPO/>,
            tab: t('networkNPO'),
            crumb:  [ t('network'),
                '❯',],
        },
        {
            id: 2,
            tabTitle: t('internNetwork'),
            component: <NetworkInternational/>,
            tab: t('internNetwork'),
            crumb:  [ t('network'),
                '❯',],
        },

    ];


    const handleTabClick = (e) => {
        const tabId = e.target.id;
        setCurrentTab(tabId);
    }

    return (
        <div className="wrapper h-auto font-inter">
            <div className="container w-full mt-[62px] md:mb-8">
                {tabs.map((tab, i) => (
                    <div key={i}>
                        {currentTab === `${tab.id}` && (
                            <BreadCrumbs crumbs={tab.crumb} title={tab.tab} key={tab.id} />
                        )}
                    </div>
                ))}
            </div>
            <div className="tabs w-[100%] mb-8">
                {tabs.map((tab, i) => (
                    <button
                        key={i}
                        id={tab.id}
                        disabled={currentTab === `${tab.id}`}
                        className="w-[50%]"
                        onClick={handleTabClick}
                    >
                        {tab.tabTitle}
                    </button>
                ))}
            </div>
            <div className="content w-[100%] mb-[62px]">
                {tabs.map((tab, i) => (
                    <div key={i}>
                        {currentTab === `${tab.id}` && (
                            <div className="w-full">{tab.component}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}


export default NetworkPage;