import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "../../components/general/breadcrumb";
import {useFetch} from "../../api/useFetch";
import {base, limitUrl, news, newsUrl, resources, uri, url} from "../../api/const";
import {ClipLoader} from "react-spinners";
import {Link, useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import {Sanitized} from "../../components/general/sanitize";
import date from "../../assets/image/home/clock.svg"
import {useMatchMedia} from "../../hooks/useMatchMedia";

const NetworkInternational = () => {

    const { isLoading } = useFetch(resources + `networking-info/?type=1`);
    const { isMobile, isTablet, isDesktop } = useMatchMedia();
    const {t, i18n} = useTranslation()
    const [response, setResponse] = useState([])
    const [pageCount, setpageCount] = useState(0);
    const limit = 8


    const getData = async () => {
        const res = await fetch(resources + `networking-info/?type=1`);
        const data = await res.json();
        setpageCount(Math.ceil(data.count / limit));
        setResponse(data);
    }

    useEffect(() => {
        getData()
    },[limit])

    const paginateData = async (count) => {
        const queryParams = new URLSearchParams({
            page: count,
            type: 1
        });

        const url = resources + `networking-info/?${queryParams.toString()}`;

        const res = await fetch(url);
        const data = await res.json();

        return data;
    };

    const handlePageClick = async (data) => {
        if( data.selected > 0 ){
            let currentPage = data.selected + 1;
            const paginateServer = await paginateData(currentPage);
            setResponse(paginateServer);
        } else {
            const paginateServer = await paginateData(1);
            setResponse(paginateServer);
        }
    };

    if (isLoading) {
        return (
            <div role="status" className='flex justify-center my-28 pb-24'>
                <ClipLoader
                    color="#1985A1"
                    size={300}
                />
            </div>
        )
    }


    return (
        <div className="containers">
            <div className="wrapper justify-between flex-wrap 2md:justify-center">
            {response.results &&
                response.results.map((item) => (
                    <Link to={`/network/${item.id}`}
                        className="card-container"
                        key={item.id}
                    >
                        {isMobile && <p className="mobile-title">
                            {
                                item[`title_${i18n.language}`].length > 60
                                    ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                                    : item[`title_${i18n.language}`]
                            }
                        </p> }
                        <div className="flex justify-between">
                            <div className="content-wrap">

                                {!isMobile && <p className="cart-title">
                                    {
                                        item[`title_${i18n.language}`].length > 60
                                            ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                                            : item[`title_${i18n.language}`]
                                    }
                                </p> }

                                <p className="cart-content">
                                    <Sanitized html={item[`short_desc_${i18n.language}`]}/>
                                </p>

                                <div className="flex flex-wrap mt-[18px] 2md:text-[12px] 1xs:mt-[8px]">
                                    {item.date && (
                                        <div className="flex">
                                            <img
                                                src={date}
                                                alt="clock"
                                                className="cart-icon"
                                            />
                                            <p className="text-sm font-medium 3sm:text-[12px]">
                                                {item.date.split('-').reverse().join('.')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="partner-container">
                                <img
                                    src={url + item.networking_slider_image[0].image}
                                    alt="values"
                                    className="partner-inside"
                                />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="paginate wrapper justify-end flex">
                <ReactPaginate
                    nextLabel="❯"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="❮"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
    );
};

export default NetworkInternational;