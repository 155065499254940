import React, {Component, useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import search from "../../assets/image/main/search-icon.png"
import Social from "./social";
import About from "../menu/about";
import CoreAdvice from "../menu/coreAdvice";
import {Language} from "../menu/Language";
import close from '../../assets/image/main/close.png'
import {useLocation, useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {asyncSearch} from "../../store/asyncAction";
import burger from "../../assets/image/general/icons8-menu-48.png";
import closes from "../../assets/image/general/icons8-close-48.png";
import arr from "../../assets/image/general/chevron-down.png";
import arr2 from "../../assets/image/general/chevron-up (1).png";
import {project} from "../statiic/data";
import logo from "../../assets/image/home/Frame.svg";

const Menu = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(true);
    const [openSearch, setOpenSearch] = useState(false)
    const searches = location.pathname === "/search"
        const [openBurger, setOpenBurger] = useState(false)
        const [show, setShow] = useState(false);
        const [arrow, setArrow] = useState(false);
        const [isShow, setIsShow] = useState(false);
        const [showProject, setIsProject] = useState(false);
    const currentUrl = window.location.href;
    const substringIndex = currentUrl.indexOf("/") + 1;
    const subString = currentUrl.substring(substringIndex);

    const toggleModal = () => {
        if(subString !== 'search'){
            navigate('search')
            setOpenModal(!openModal);
            setOpenSearch(!openSearch)
        } else {
            setOpenModal(!openModal);
            setOpenSearch(!openSearch)
        }



    }
    const toggleSearch = () => {
        setOpenSearch(!openSearch);
    }
    const toggleAccordion = () => {setShow(!show);};
    const toggleArrow = () => {setArrow(!arrow);};
    const switchAccordion = () => {setIsShow(!isShow);};
    const changeAccordion = () => {setIsProject(!showProject);};

    // useEffect(() => {
    //    if(searches){
    //        setOpenSearch(true)
    //        setOpenModal(false);
    //    } else {
    //        setOpenSearch(false)
    //        setOpenModal(true);
    //    }
    // },[location])

    const handleChange = (e) => {
        const val = e.target.value
        e.preventDefault();
        dispatch(asyncSearch({ value: val }));
        // navigate('/search');
    }

    const toggleMenu = () => { setOpenBurger(!openBurger)}

        return (
          <div className="relative mb-0 font-general">
            {openModal && (
              <div className="bg-blue">
                <div className="wrapper justify-between h-[85px] flex items-center font-inter">
                  <div
                    className="hidden lg:block cursor-pointer"
                    onClick={toggleMenu}
                  >
                    {openBurger ? (
                      <img src={closes} alt="close" />
                    ) : (
                      <img src={burger} alt="open" />
                    )}
                  </div>
                  <NavLink to="/">
                    <img
                      className="w-[175px] 4xs:w-[120px] m-auto justify-center h-[52px] my-[8px] hidden lg:block cursor-pointer 5xs:hidden 1xs:w-[140px] 1xs:h-[40px] items-center"
                      src={logo}
                      alt="logo"
                    />
                  </NavLink>
                  <div className="hidden lg:flex">
                    <img
                      src={search}
                      alt="search"
                      className="w-[20px] h-[20px] cursor-pointer mt-[2px] mr-2"
                      onClick={toggleModal}
                    />
                    <Language />
                  </div>
                  <div className="flex items-center justify-between lg:hidden">
                    <NavLink to="/">
                      <img
                        className="w-[175px] h-[52px] my-[8px] mr-[90px] cursor-pointer md:w-[46px] md:h-[46px] md:mr-1 sm:ml-2 1xs:ml-0 1xs:w-[40px] 1xs:h-[40px] items-center"
                        src={logo}
                        alt="logo"
                      />
                    </NavLink>
                    <div className="flex w-[688px] pt-2 align-middle justify-between text-base lg:hidden">
                      <NavLink
                        to="/about"
                        className={({ isActive }) =>
                          isActive ? 'hover menu' : 'menu'
                        }
                      >
                        {t('about')}
                      </NavLink>
                      <NavLink
                        to="/"
                        className={'menu'}
                        onClick={toggleMenu}
                        onClick={() =>
                          window.open('https://aqcaplatform.asia/map/')
                        }
                      >
                        {t('monitoring')}
                      </NavLink>
                      <NavLink
                        to="/expert_country"
                        className={({ isActive }) =>
                          isActive ? 'hover menu' : 'menu'
                        }
                      >
                        {t('expert')}
                      </NavLink>
                      {/*<NavLink*/}
                      {/*    to="/solutions"*/}
                      {/*    className={({ isActive }) =>*/}
                      {/*        isActive ? 'hover menu' : 'menu'*/}
                      {/*    }*/}
                      {/*>*/}
                      {/*    {t('analytic')}*/}
                      {/*</NavLink>*/}
                      <NavLink
                        to="/resources"
                        className={({ isActive }) =>
                          isActive ? 'hover menu ' : 'menu'
                        }
                      >
                        {t('resources')}
                      </NavLink>
                      <NavLink
                        to="/events"
                        className={({ isActive }) =>
                          isActive ? 'hover menu' : 'menu'
                        }
                      >
                        {t('events')}
                      </NavLink>
                      <NavLink
                        to="/news"
                        className={({ isActive }) =>
                          isActive ? 'hover menu' : 'menu'
                        }
                      >
                        {t('news')}
                      </NavLink>
                    </div>
                    <img
                      src={search}
                      alt="search"
                      className="w-[20px] h-[20px] cursor-pointer mx-[76px]"
                      onClick={toggleModal}
                    />
                    <Language />
                  </div>
                </div>
              </div>
            )}
            {openSearch && (
              <div className="w-full h-[85px] bg-blueLight z-10">
                <div className="wrapper py-[22px] px-2">
                  <input
                    type="text"
                    autoFocus={true}
                    className="bg-blueLight border-none outline-none w-[98.5%] h-[24px] font-medium text-lg lg:w-[96.5%] 2md:w-[94.5%]"
                    placeholder={t('search')}
                    onChange={(e) => handleChange(e)}
                  />
                  <img
                    src={close}
                    alt="close"
                    className="py-2 cursor-pointer"
                    onClick={toggleModal}
                  />
                </div>
              </div>
            )}
            {openBurger && (
              <div className="w-full max-h-auto bg-blue pb-4">
                <div className="wrapper">
                  <div className=" pb-1">
                    <NavLink
                      to="/about"
                      className="menu-list ml-8"
                      onClick={toggleMenu}
                    >
                      {t('about')}
                    </NavLink>
                    <NavLink
                      to="/"
                      className="menu-list ml-8"
                      onClick={toggleMenu}
                      onClick={() =>
                        window.open('https://aqcaplatform.asia/map/')
                      }
                    >
                      {t('monitoring')}
                    </NavLink>
                    <NavLink
                      to="/expert_country"
                      className="menu-list ml-8"
                      onClick={toggleMenu}
                    >
                      {t('expert')}
                    </NavLink>
                    <NavLink
                      to="/resources"
                      className="menu-list ml-8"
                      onClick={toggleMenu}
                    >
                      {t('resources')}
                    </NavLink>
                    <NavLink
                      to="/events"
                      className="menu-list ml-8"
                      onClick={toggleMenu}
                    >
                      {t('events')}
                    </NavLink>
                    <NavLink
                      to="/analytics"
                      className="menu-list  ml-8"
                      onClick={toggleMenu}
                    >
                      {t('analytic')}
                    </NavLink>
                    <NavLink
                      to="events"
                      className="menu-list  ml-8"
                      onClick={toggleMenu}
                    >
                      {t('events')}
                    </NavLink>
                    <NavLink
                      to="/news"
                      className="menu-list  ml-8"
                      onClick={toggleMenu}
                    >
                      {t('news')}
                    </NavLink>
                    <NavLink
                      to="solutions"
                      className="menu-list  ml-8"
                      onClick={toggleMenu}
                    >
                      {t('solution')}
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
}

export default Menu;