import React, {Component, useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";

import {useLocation, useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import burger from "../../../assets/image/general/icons8-menu-48.png";
import closes from "../../../assets/image/general/icons8-close-48.png";
import arr from "../../../assets/image/general/chevron-down.png";
import arr2 from "../../../assets/image/general/chevron-up (1).png";
import logo from "../../../assets/image/home/Frame.svg";

const FooterMenu = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(true);
    const [openSearch, setOpenSearch] = useState(false)
    const searches = location.pathname === "/search"
        const [openBurger, setOpenBurger] = useState(false)
        const [show, setShow] = useState(false);
        const [arrow, setArrow] = useState(false);
        const [isShow, setIsShow] = useState(false);
        const [showProject, setIsProject] = useState(false);
         const [showMenu, setShowMenu] = useState(false);

         const toggleMenu = () => {

                setShowMenu(!showMenu);
         }

         const toggleBurger = () => {

         }


    const toggleModal = () => {
        setOpenModal(!openModal);
        setOpenSearch(!openSearch)
    }
    const toggleSearch = () => {
        setOpenSearch(!openSearch);
    }
    const toggleAccordion = () => {setShow(!show);};
    const toggleArrow = () => {setArrow(!arrow);};
    const switchAccordion = () => {setIsShow(!isShow);};
    const changeAccordion = () => {setIsProject(!showProject);};

    useEffect(() => {
       if(searches){
           setOpenSearch(true)
           setOpenModal(false);
       } else {
           setOpenSearch(false)
           setOpenModal(true);
       }
    },[location])

        return (
          <div className="font-general">
            <div className="bg-blue">
              <div className="wrapper justify-between h-[85px] flex items-center font-inter px-1">
                <div className="w-full flex items-center justify-between sm:justify-center">
                  <NavLink to="/" className="2lg:hidden">
                    <img
                      className="w-[175px] h-[52px] my-[8px] mr-14 cursor-pointer md:w-[46px] md:h-[46px] md:mr-1 sm:ml-2 1xs:ml-0 1xs:w-[40px] 1xs:h-[40px] items-center"
                      src={logo}
                      alt="logo"
                    />
                  </NavLink>
                  <div className="flex flex-wrap w-[924px] sm:w-[324px] pt-2 align-middle justify-between text-base">
                    <NavLink
                      to="/about"
                      className={({ isActive }) =>
                        isActive ? 'hover fmenu' : 'fmenu'
                      }
                    >
                      {t('about')}
                    </NavLink>
                    <NavLink
                      to="/"
                      className={`fmenu`}
                      onClick={toggleMenu}
                      onClick={() =>
                        window.open('https://aqcaplatform.asia/map/')
                      }
                    >
                      {t('monitoring')}
                    </NavLink>
                    {/*<NavLink*/}
                    {/*  to="/aqca"*/}
                    {/*  className={({ isActive }) =>*/}
                    {/*    isActive ? 'hover fmenu ' : 'fmenu'*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  {t('aqca')}*/}
                    {/*</NavLink>*/}
                    <NavLink
                      to="/expert_country"
                      className={({ isActive }) =>
                        isActive ? 'hover fmenu' : 'fmenu'
                      }
                    >
                      {t('expert')}
                    </NavLink>
                    <NavLink
                      to="/solutionsCountry"
                      className={({ isActive }) =>
                        isActive ? 'hover fmenu' : 'fmenu'
                      }
                    >
                      {t('solution')}
                    </NavLink>
                    <NavLink
                      to="/events"
                      className={({ isActive }) =>
                        isActive ? 'hover fmenu' : 'fmenu'
                      }
                    >
                      {t('events')}
                    </NavLink>
                    <NavLink
                      to="/news"
                      className={({ isActive }) =>
                        isActive ? 'hover fmenu' : 'fmenu'
                      }
                    >
                      {t('news')}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
}

export default FooterMenu;