import React from 'react';

const Copyright = () => {
    return (
        <div className="wrapper w-full sm:w-[250px] h-[85px] flex justify-center items-center sm:justify-center">
           <p className="text-blue text-sm font-normal">© 2023 Air Quality Central Asia Dialogue Platform. All rights reserved.</p>
        </div>
    );
};

export default Copyright;