import React from 'react';
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import analityc from "../../assets/image/resources/Icon (1).svg"
import {BreadCrumb} from "../general/breadcrumb";
import gradient from "../../assets/image/resources/gradient.svg"
import main from "../../assets/image/home/banner.png";
import {data, data2} from "./data"
import {Link} from "react-router-dom";

const Resources = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    window.scrollTo(0, 0);

    return (
        <div className="w-full min-h-[943px] py-[62px]">
            <div className="title">{t('resources')}</div>
            <BreadCrumb/>
            <div className="grid grid-cols-4 gap-8 mt-[62px] 2md:grid-cols-3 1sm:grid-cols-2 xs:grid-cols-1 items-center ">
                {data.map(item => (
                    <Link to={item.link} className="hover:scale-110 cursor-pointer justify-self-center margin-auto">
                    <div className="h-[222px] w-[188px] rounded-t-3xl pt-[60px] shadow-resources bg-no-repeat bg-cover " style={{
                        backgroundImage: `url(${gradient})`,
                    }}>
                        <img src={item.img} className="m-auto"/>
                    </div>
                        <div className="h-[45px] w-[188px] rounded-b-3xl shadow-resources bg-no-repeat bg-cover">
                            <div className="text-base text-black font-semibold pt-[-20px] text-center uppercase leading-4">{t(`${item.title}`)}</div>
                        </div>
                    </Link>
                ))}
            </div>
            {/*    <div className="flex flex-wrap justify-around mb-[134px]">*/}
            {/*    {data2.map(item => (*/}
            {/*        <Link to={item.link} className="block hover:shadow-2xl cursor-pointer">*/}
            {/*            <div className="h-[222px] w-[188px] rounded-t-3xl pt-[60px] shadow-resources bg-no-repeat bg-cover" style={{*/}
            {/*                backgroundImage: `url(${gradient})`,*/}
            {/*            }}>*/}
            {/*                <img src={item.img} className="m-auto"/>*/}
            {/*            </div>*/}
            {/*            <div className="h-[45px] w-[188px] rounded-b-3xl shadow-resources bg-no-repeat bg-cover">*/}
            {/*                <div className="text-base text-black font-semibold pt-[-20px] text-center uppercase leading-4">{t(`${item.title}`)}</div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*    ))}*/}
            {/*</div>*/}
        </div>
    );
};

export default Resources;