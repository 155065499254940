import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        data: [],
        isEmpty: true,
        form: ""
    },
    reducers: {
        getSearchSuccess(state, action) {

            const obj = action.payload;

            state.data = []; // Reset state.data to an empty array

            for (let k in obj) {
                if (obj[k].length > 0) {
                    state.data = obj;
                }
            }

            const isEmpty = (obj) => {
                for (let k in obj) {
                    if (obj[k].length > 0) {
                        return false;
                    }
                }
                return true;
            };
            state.isEmpty = isEmpty(action.payload);
        },
        // getSearchSuccess(state, action) {
        //     console.log('eee', action.payload)
        //     if(!action.payload){
        //         state.data.length = 0
        //         alert('hi')
        //     } else {
        //         state.data = action.payload
        //         console.log('d', state.data)
        //     }
        //     const isEmpty = (obj) => {
        //         for (let k in obj) {
        //             if (obj[k].length > 0) {
        //                 return false;
        //             }
        //         }
        //         return true;
        //     };
        //     state.isEmpty = isEmpty(action.payload);
        // },
        setForm(state, action){
            state.form = action.payload
        },

    },
});

export const { getSearchSuccess, setForm } = searchSlice.actions;


export default searchSlice.reducer;
