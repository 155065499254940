import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useFetch} from "../../../api/useFetch";
import {limit2, main, month, resources, url} from "../../../api/const";
import date from "../../../assets/image/home/Icon (1).svg"
import location from "../../../assets/image/home/Icon (2).svg"
import {Sanitized} from "../../general/sanitize";
import {useEffect, useState} from "react";
import axios from "axios";

export const EventsHome = () => {
    const [response, setResponse] = useState([])
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const {t,i18n} = useTranslation()


    const getSliders = async () => {
        const res = await axios.get(resources + 'last-analytics-info/')
        setResponse(res.data)
    }

    useEffect(() => {
        getSliders()
    },[])

    return (
      <div className="max-w-[334px] 2lg:max-w-full relative z-10 text-general">
        <div className="flex justify-between mt-[4px]">
          <p className="title ml-2">{t('solution')}</p>
          <Link to="/solutions" className="all-view mr-2">
            {t('all')}
          </Link>
        </div>
        <div className="flex flex-wrap wrapper">
          {' '}
          {response.results &&
            response.results.slice(0, 2).map((item) => (
              <Link
                to={`/solutions/${item.id}`}
                className="block mb-8 max-w-[384px] h-[416px] 1md:h-[370px] m-auto shadow-xl rounded bg-white pb-4 leading-5 cursor-pointer hover:shadow-2xl
                                 xl:max-w-[340px] sm:max-w-[384px] lg:max-w-[384px] xs:max-w-[300px] 4xs:w-[280px] 1md:max-w-[340px]"
              >
                <div className="mb-3 h-[247px] 1md:h-[200px] overflow-hidden rounded-t xl:w-[340px] 2lg:w-[340px] 1sm:w-full xs:w-[300px] 4xs:w-[280px] xs:h-[230px] xs:mb-0 3lg:w-full 2lg:w-full">
                  <img
                    src={url + item.analytics_slider_image[0].image}
                    alt="cart-img"
                    className="h-auto w-[100%] rounded-t bg-center bg-cover"
                  />
                </div>
                <div className="px-2.5">
                  <p className="cart-title">
                    {item[`title_${i18n.language}`].length > 60
                      ? item[`title_${i18n.language}`].slice(0, 60) + '...'
                      : item[`title_${i18n.language}`]}
                  </p>
                  <p className="cart-content">
                    <Sanitized html={item[`short_desc_${i18n.language}`]} />
                  </p>
                  <div className="max-w-[324px] my-4 text-sm font-medium">
                    <div className="text-grey flex">
                      <img src={date} className="cart-icon" alt="dots" />
                      <span className="cart-contact">{t('date')}</span>
                      <span className="text-black ml-[6px] cart-contact">
                        {item.date.split('-').reverse().join('.')}
                        {/*{item.date_start.split("-").slice(2).join()}-{item.date_end.split("-").slice(2).join()}   {t(`${month[item.date_end.split("-").slice(1,2).join()]}`)}   {item.date_end.split("-").slice(0,1).join()}*/}
                      </span>
                    </div>
                    {/*<div className="text-grey flex">*/}
                    {/*    <img*/}
                    {/*        src={location}*/}
                    {/*        className="cart-icon"*/}
                    {/*        alt="dots"*/}
                    {/*    />*/}
                    {/*    <span className="cart-contact">{t('location')}</span>*/}
                    {/*        <span className="text-black ml-[6px] cart-contact">*/}
                    {/*           {item[`place_${i18n.language}`]}*/}
                    {/*          </span>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    );
};