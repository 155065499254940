import {useLocation, useParams, } from "react-router-dom";
import {useNavigate} from "react-router";
import arrow from "../../assets/image/general/arr.svg"
import arrow2  from "../../assets/image/general/arr2.svg"
import {useTranslation} from "react-i18next";

export const BreadCrumb = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const {pathname} = location
    const {t} = useTranslation()

    return (
      <div className="mb-8 font-inter sm:ml-4 1xs:pt-0 1xs:mb-6">
        {pathname && (
          <>
            <div className="text-lg font-medium text-grey 1xs:text-[14px] 1xs:mt-2 2xs:text-[12px]">
              <div
                className="text-lg font-medium text-white"
                onClick={() => navigate('/')}
              >
                {pathname === '/resources' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                  </span>
                ) : null}
                {pathname === '/n' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('news')}
                  </span>
                ) : null}
                {pathname === '/solutionsCountry' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('solution')}
                  </span>
                ) : null}
                {pathname === '/investigate' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('investigate')}
                  </span>
                ) : null}
                {pathname === '/events' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('events')}
                  </span>
                ) : null}
                {pathname === '/resolves' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resolves')}
                  </span>
                ) : null}
                {pathname === '/aqca' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('aqca')}
                  </span>
                ) : null}
                {pathname === '/newsCountry' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('investigate')}
                  </span>
                ) : null}
                {pathname === '/database' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('data')}
                  </span>
                ) : null}
                {pathname === '/network' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('network')}
                  </span>
                ) : null}
                {pathname === '/grants' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('grants')}
                  </span>
                ) : null}
                {pathname === '/expert_country' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('expert')}
                  </span>
                ) : null}
                {pathname === '/analytics' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('analytic')}
                  </span>
                ) : null}
                {pathname === '/progress' ? (
                  <span className="crumbs">
                    {t('home')}
                    <span className="arrow">\</span>
                    {t('resources')}
                    <span className="arrow">\</span>
                    {t('progress')}
                  </span>
                ) : null}
              </div>
              {pathname === '/projects' ? (
                <span className="crumbs" onClick={() => navigate('/about')}>
                  {t('about')}
                  <img src={arrow} alt="" className="arrow" />
                  {t('projects')}
                </span>
              ) : null}
            </div>
            <div
              className="text-lg font-medium text-white"
              onClick={() => navigate(-1)}
            >
              {pathname === '/ministry' ? (
                <span className="crumbs" onClick={() => navigate('/about')}>
                  {t('about')}
                  <img src={arrow2} alt="" className="mx-4 w-2 h-4 xs:mx-2" />
                  {t('ministry')}
                </span>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
};