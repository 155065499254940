import React from 'react';
import {BreadCrumb} from "../components/general/breadcrumb";
import ClipLoader from "react-spinners/ClipLoader";
import {Sanitized} from "../components/general/sanitize";
import LinkResource from "../components/linkResource";
import {useTranslation} from "react-i18next";
import {useFetch} from "../api/useFetch";
import {allowTags, newsUrl, resources, uri} from "../api/const";
import {CreateMarkup, CustomDompurify} from "../components/general/dompurify";
import DOMPurify from "dompurify";

const DatabasePage = () => {
    const { isLoading, response } = useFetch(resources + 'database-info/');
    const {t,i18n} = useTranslation()
    window.scrollTo(0, 0);


    if (isLoading) {
        return (
            <div role="status" className='flex justify-center my-28 pb-24'>
                <ClipLoader
                    color="#1985A1"
                    size={300}
                />
            </div>
        )
    }

    DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
        if (node.tagName === 'A') {
            node.classList.add('links');
        }
        if (node.nodeName === 'SPAN' && node.style.color === '#3498db') {
            node.nodeValue = node.nodeValue.replace(/\r\n\r\n/g, '\n');
        }
        if (node.tagName === 'IMAGE') {
            node.classList.add('iframe');
        }
    });

    return (
        <div className="wrapper min-h-[60vh] py-[62px] 2md:py-[32px]">
            <div className="w-full">
                <div className="title">{t('data')}</div>
                <BreadCrumb/>
            </div>
                    <div
                        className="flex w-[1236px] m-auto rounded bg-white mb-10 items-center">
                        {response.results.map(item => (
                            <div className="text-content w-full " key={item.id}>
                                <CustomDompurify html={item[`data_${i18n.language}`].replace(/\r\n\r\n/g, "<br/>")}/>
                            </div>
                        ))}
                    </div>
        </div>
    );
};

export default DatabasePage;