import React from 'react';
import {BreadCrumb} from "../components/general/breadcrumb";
import LinkResource from "../components/linkResource";
import {useFetch} from "../api/useFetch";
import {allowTags, base, newsUrl, resources, uri} from "../api/const";
import {useTranslation} from "react-i18next";
import AqcaForm from "../components/resources/aqca/aqcaForm";
import {Sanitized} from "../components/general/sanitize";
import {ClipLoader} from "react-spinners";
import {CreateMarkup, CustomDompurify} from "../components/general/dompurify";
import DOMPurify from "dompurify";

const AqcaPage = () => {
    const {isLoading, response} = useFetch(resources + `membership-info/`);
    const {t, i18n} = useTranslation()
    window.scrollTo(0, 0);

    console.log(response)

    if (isLoading) {
        return (
            <div role="status" className='flex justify-center my-28 pb-24'>
                <ClipLoader
                    color="#1985A1"
                    size={300}
                />
            </div>
        )
    }

    // DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    //     if (node.nodeName === 'SPAN' && node.style.color === '#3498db') {
    //         node.nodeValue = node.nodeValue.replace(/\r\n\r\n/g, '\n');
    //     }
    //     if (node.tagName === 'A') {
    //         node.classList.add('links');
    //     }
    // });



    return (
        <div>
            <div className="containers">
                <div>
                    <div className="title">{t('aqca')}</div>
                    <BreadCrumb/>
                </div>
                {response.results &&
                    <>
                        <div
                            className="flex max-w-[1236px] m-auto rounded bg-white mb-10 items-center">
                            {response.results.map(item => (
                                <div className="text-content" key={item.id}>
                                    <CustomDompurify html={item[`data_${i18n.language}`].replace(/\r\n\r\n/g, "<br/>")}/>
                                    {/*<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item[`data_${i18n.language}`])}}></div>*/}
                                </div>
                            ))}
                        </div>
                        {/*{response.map(item => (*/}
                        {/*    <div>*/}
                        {/*        <div className="title-link">{t('resource-link')}</div>*/}
                        {/*        <div>*/}
                        {/*            {item.membership_links.map(item => (*/}
                        {/*                <div className="text-link" onClick={() => window.open(item.link)}>{item.link}</div>*/}
                        {/*            ))}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                    </>
                }
            </div>
            <AqcaForm/>
        </div>
    );
};

export default AqcaPage;