import React from 'react';
import USA from "../../../assets/image/home/1.svg"
import DRA from "../../../assets/image/home/3.svg"
import BROT from "../../../assets/IMG_4287.jpg"
import insta from "../../../assets/image/main/Path.svg";
import meta from "../../../assets/image/main/Vector.svg";
import unep from '../../../assets/unep.png';
import camp from '../../../assets/TechCamp_Bishkek.png';
import embasy from '../../../assets/TWO FLAGS EMBASSY LOGO_BLACK_LETTERS.png';
import { useTranslation } from 'react-i18next';

const FooterContact = () => {
  const { t } = useTranslation();

  return (
    <div className=" w-full bg-blue py-8 sm:py-4 px-1">
      <div className="flex justify-between wrapper">
        <div className="max-w-[235px] sm:mb-2">
          <div className="menu">aqcaplatform@gmail.com </div>
          <div className="menu">{t('contact')}</div>
        </div>
        <div className="text-[15px] text-light w-[473px] sm:hidden">
          {t('rules')}
        </div>
        <div className="flex w-[240px] lg:w-[473px] 3xs:w-[234px] lg:mt-2 sm:mt-0 flex-wrap items-end">
          <img
            src={USA}
            alt="usa"
            className="w-[62px] h-[33px] mr-4 flex items-end"
          />
          <img
            src={unep}
            alt="usa"
            className="w-[62px] h-[33px] mr-4  flex items-end"
          />
          <div className="w-[62px] h-[33px] mr-4 flex items-end">
          <img
            src={camp}
            alt="usa"
            className="h-auto w-[100%]"
          />
          </div>
          <img
            src={embasy}
            alt="usa"
            className="w-[62px] h-[33px] mr-4  flex items-end"
          />
          <img
            src={DRA}
            alt="dra"
            className="w-[62px] h-[33px] mr-4  flex items-end"
          />
          <img
            src={BROT}
            alt="brot"
            className="w-[62px] h-[33px] flex items-end 3sm:mt-2"
          />
        </div>
        <div className="flex items-end">
          <div
            className="social ml-[10px]"
            onClick={() =>
              window.open(
                'https://www.instagram.com/aqcaplatform/?igshid=MjkzY2Y1YTY%3D'
              )
            }
          >
            <img
              src={insta}
              alt="social"
              className="w-[17px] h-[17px] cursor-pointer "
            />
          </div>
          <div
            className="social ml-[10px]"
            onClick={() =>
              window.open(
                'https://www.facebook.com/people/aqcaplatform/100090820775566/'
              )
            }
          >
            <img
              src={meta}
              alt="social"
              className="w-[9px] h-[16px] cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterContact;