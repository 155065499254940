import React, {useRef, useState} from "react";
import {Autoplay, Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {api, url} from "../../api/const";
import InfoImage from "../../pages/imageModal/infoImage";

export const ImagesSlider = props => {
    const [activeThumb, setActiveThumb] = useState()
    const swiperRef = useRef();
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const openModal = (image) => {
        setOpenRegisterModal(true)
        localStorage.setItem("image", image)
    }

    const autoplayEnabled = props.images.length > 1;
    const play = props.images.length > 1 ? true : false

    const settings = {
        breakpoints: {
            280: {
                slidesPerView: 2,
            },
            320: {
                slidesPerView: 3,
            }
        }
    }

    return (
    <div>
        <Swiper
            hashNavigation={{
                watchState: true,
            }}
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            pagination={{
                el: '.swiper-pagination',
                type: 'bullets'
            }}
            slidesPerView={1}
            loop={true}
            modules={[Autoplay, Thumbs]}
            thumbs={{ swiper: activeThumb }}
            autoplay={{
                delay: 9000,
                disableOnInteraction: false,
                enabled: autoplayEnabled,
            }}
            speed={900}
        >
            {
                props.images.map((item, i) => (
                    <div key={i}>
                    <SwiperSlide>
                        {/*w-[432px] h-[245px] 1xs:w-[320px] 1xs:h-[200px] 4xs:w-[270px] mr-[62px] 1xs:mr-0 mb-2 z-10 overflow-hidden float-left cursor-pointer*/}
                        <div className="flex w-[432px] min-h-[300px] 1xs:w-[320px] 4xs:w-[300px] mr-[62px] 1xs:mr-0 mb-2 z-10 overflow-hidden float-left cursor-pointer*"
                             onClick={() => openModal(item.image)}>
                            <img
                                src={url + item.image}
                                alt="cart-img"
                                className="h-auto w-[100%] object-cover rounded-t self-center"
                            />
                        </div>
                    </SwiperSlide>
                    </div>
                ))
            }
            <div ref={navigationPrevRef}
                 className="banner-prev absolute top-[44%] left-[20px]"
            ></div>
            <div ref={navigationNextRef}
                 className="banner-next absolute top-[45%] right-[20px]"
            ></div>
        </Swiper>
        {props.images.length > 1 &&
        <div className="mini-inner-swiper">
        <Swiper
            hashNavigation={{
                watchState: true,
            }}
            loop={false}
            speed={600}
            spaceBetween={8}
            slidesPerView={props.images.length}
            onSwiper={setActiveThumb}
            modules={[ Thumbs]}
        >
            {
                props.images.map((item) => (
                    <SwiperSlide>
                        <div className="flex m-auto align-center">
                        <img
                            src={url + item.image}
                            alt="cart-img"
                            className="w-[112px] h-[81px] self-center"
                        />
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
            {/*<div*/}
            {/*    className="detail-prev absolute top-[30px] left-[-30px]"*/}
            {/*    onClick={() => swiperRef.current.slidePrev()}*/}
            {/*></div>*/}
            {/*<div*/}
            {/*    className="detail-next absolute top-[30px] right-[-25px]"*/}
            {/*    onClick={() => swiperRef.current.slideNext()}*/}
            {/*></div>*/}
        </div>
        }
        {openRegisterModal && openRegisterModal && (
            <InfoImage
                openRegisterModal={openRegisterModal}
                setOpenRegisterModal={() => setOpenRegisterModal(false)}
            />
        )}
        <div className="swiper-pagination" />
    </div>
        )
}