import React from 'react';
import Advertisement from "../components/main/advertisement";
import Resources from "../components/resources/resources";

const Resourcespage = () => {
    return (
        <>
            <div className="wrapper">
                <Resources/>
            </div>
            <Advertisement/>
        </>
    );
};

export default Resourcespage;