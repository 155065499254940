import React, {useEffect, useRef, useState} from 'react';
import {api, main, base, limitUrl, mainUrl, uri, url} from '../../../api/const';
import { useFetch } from '../../../api/useFetch';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import '../../../index.css';
import {ClipLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import axios from "axios";
import GalleryInfo from "../../../pages/galeryPage/galleryInfo";
import MobileForm from "./mobileForm";
import {Sanitized} from "../../general/sanitize";
import { NavLink } from 'react-router-dom';

const Banner = () => {
    const swiperRef = useRef();
    const { isLoading } = useFetch(main + 'get-main/');
    const [response, setResponse] = useState([]);
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const { t, i18n } = useTranslation();

    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    const getSliders = async () => {
        const res = await axios.get(main + 'get-main/');
        setResponse(res.data);
    };

    useEffect(() => {
        getSliders();
    }, []);

    const openModal = () => {
        setOpenRegisterModal(!openRegisterModal);
    };

    console.log(response);

    return (
        <div
            className="block relative bg-white w-[650px] xs:w-[350px]
        2xs:w-[320px] 4xs:w-[290px] 2sm:w-[500px] 1xs:w-[450px]
        3sm:w-[400px] h-[546px] m-auto pt-[22px] px-[22px]
        3sm:h-[420px] 4xs:h-[365px] 2sm:pt-[12px] 2sm:px-[16px] 1xs:px-[10px]"
        >
            <div className="flex justify-between mb-[10px] 2sm:mb-[5px]">
                <div className="font-semibold text-lg text-black font-general">
                    {t('main')}
                </div>
                <div
                    className="hidden 2lg:block font-semibold text-base mt-[2px] text-blue font-general cursor-pointer"
                    onClick={() => openModal()}
                >
                    {t('becomeMob')}
                </div>
            </div>
            {isLoading && (
                <div
                    role="status"
                    className="flex justify-center mt-24 3sm:mt-12 w-full"
                >
                    <ClipLoader color="#1985A1" size={200} />
                </div>
            )}
            <Swiper
                hashNavigation={{
                    watchState: true,
                }}
                autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    el: '.swiper-custom-pagination',
                    type: 'bullets',
                }}
                grabCursor={true}
                loop={true}
                slidesPerView={1}
                speed={800}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                modules={[Autoplay, Pagination, Navigation]}
            >
                {response.news &&
                    response.news.map((item) => (
                        <SwiperSlide key={item.id}>
                            <div className="slider_container">
                                <img
                                    src={url + item.news_slider_image[0].image}
                                    alt="cart-img"
                                    className="h-auto w-[100%] rounded-t bg-center bg-cover"
                                />
                                {/*<div className={"event-img"}*/}
                                {/*     style={{backgroundImage: `url(${url + item.news_slider_image[0].image})`}}>*/}
                                {/*</div>*/}
                                <NavLink
                                    to={`analytics/${item.id}`}
                                    className="h-[104px] 3sm:h-[90px] xs:h-[80px] w-full 2xs:h-[80px] 3xs:h-[100px] bg-gradient-slider absolute bottom-0 left-0 p-4  1xs:p-1 text-white flex flex-wrap"
                                >
                                    <div className="w-full">
                                        <p className="slider-title">
                                            <Sanitized html={item[`title_${i18n.language}`]} />
                                        </p>
                                        <div className="flex justify-between mt-[13px] 1xs:mt-[8px]">
                                            <p className="font-normal text-[15px] text-white font-general">
                                                {t('analytic')}
                                            </p>
                                            <p className="font-medium text-base font-general text-white">
                                                {item.date.split('-').reverse().join('.')}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </SwiperSlide>
                    ))}
                {response.events &&
                    response.events.map((item) => (
                        <SwiperSlide key={item.id}>
                            <div className="slider_container">
                                <div
                                    className={'event-img'}
                                    style={{
                                        backgroundImage: `url(${
                                            url + item.events_slider_image[0].image
                                        })`,
                                    }}
                                ></div>
                                {/*<img*/}
                                {/*    src={url + item.events_slider_image[0].image}*/}
                                {/*    alt=""*/}
                                {/*    className="w-full h-auto object-cover bg-no-repeat relative"*/}
                                {/*/>*/}
                                <NavLink
                                    to={`events/${item.id}`}
                                    className="h-[104px] 3sm:h-[80px] xs:h-[80px] w-full 2xs:h-[80px] 3xs:h-[90px] bg-gradient-slider absolute bottom-0 left-0 p-4 1xs:p-2 text-white flex flex-wrap"
                                >
                                    <div className="w-full">
                                        <p className="slider-title">
                                            <Sanitized html={item[`title_${i18n.language}`]} />
                                        </p>
                                        <div className="flex justify-between mt-[13px] 1xs:mt-[8px]">
                                            <p className="font-normal text-[15px] text-white font-general">
                                                {t('events')}
                                            </p>
                                            <p className="font-medium text-base font-general text-white">
                                                {item.date_end.split('-').reverse().join('.')}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </SwiperSlide>
                    ))}
                {response.decision &&
                    response.decision.map((item) => (
                        <SwiperSlide key={item.id}>
                            <div className="slider_container">
                                <img
                                    src={url + item.decision_slider_image[0].image}
                                    alt="cart-img"
                                    className="h-auto w-[100%] rounded-t bg-center bg-cover"
                                />
                                {/*<div className={"event-img"}*/}
                                {/*     style={{backgroundImage: `url(${url + item.decision_slider_image[0].image})`}}>*/}
                                {/*</div>*/}
                                <NavLink
                                    to={`solutions/${item.id}`}
                                    className="h-[104px] 3sm:h-[90px] xs:h-[80px] w-full 2xs:h-[80px] 3xs:h-[100px] bg-gradient-slider absolute bottom-0 left-0 p-4  1xs:p-1 text-white flex flex-wrap"
                                >
                                    <div className="w-full">
                                        <p className="slider-title">
                                            <Sanitized html={item[`title_${i18n.language}`]} />
                                        </p>
                                        <div className="flex justify-between mt-[13px] 1xs:mt-[8px]">
                                            <p className="font-normal text-[15px] text-white font-general">
                                                {t('solution')}
                                            </p>
                                            <p className="font-medium text-base font-general text-white">
                                                {item.date.split('-').reverse().join('.')}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </SwiperSlide>
                    ))}
                {response.analytics &&
                    response.analytics.map((item) => (
                        <SwiperSlide key={item.id}>
                            <div className="slider_container">
                                <img
                                    src={url + item.analytics_slider_image[0].image}
                                    alt="cart-img"
                                    className="h-auto w-[100%] rounded-t bg-center bg-cover"
                                />
                                {/*<div className={"event-img"}*/}
                                {/*     style={{backgroundImage: `url(${url + item.analytics_slider_image[0].image})`}}>*/}
                                {/*</div>*/}
                                {/*<img*/}
                                {/*    src={url + item.analytics_slider_image[0].image}*/}
                                {/*    alt=""*/}
                                {/*    className="w-full h-auto object-cover bg-no-repeat relative bg-bottom"*/}
                                {/*/>*/}
                                <NavLink
                                    to={`news/${item.id}`}
                                    className="h-[104px] 3sm:h-[90px] xs:h-[80px] w-full 2xs:h-[80px] 3xs:h-[100px] bg-gradient-slider absolute bottom-0 left-0 p-4  1xs:p-1 text-white flex flex-wrap"
                                >
                                    <div className="w-full">
                                        <p className="slider-title">
                                            <Sanitized html={item[`title_${i18n.language}`]} />
                                        </p>
                                        <div className="flex justify-between mt-[13px] 1xs:mt-[8px]">
                                            <p className="font-normal text-[15px] text-white font-general">
                                                {t('news')}
                                            </p>
                                            <p className="font-medium text-base font-general text-white">
                                                {item.date.split('-').reverse().join('.')}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </SwiperSlide>
                    ))}
                {response.research &&
                    response.research.map((item) => (
                        <SwiperSlide key={item.id}>
                            <div className="slider_container">
                                {/*<div className="mb-3 h-[247px] 1md:h-[200px] overflow-hidden rounded-t xl:w-[340px] 2lg:w-[340px] 1sm:w-full xs:w-[300px] 4xs:w-[280px] xs:h-[230px] xs:mb-0 3lg:w-full 2lg:w-full">*/}
                                <img
                                    src={url + item.research_slider_image[0].image}
                                    alt="cart-img"
                                    className="h-auto w-[100%] rounded-t bg-center bg-cover"
                                />
                                {/*</div>*/}
                                <div
                                    className={'event-img'}
                                    style={{
                                        backgroundImage: `url(${
                                            url + item.research_slider_image[0].image
                                        })`,
                                    }}
                                ></div>
                                <NavLink
                                    to={`investigate/${item.id}`}
                                    className="h-[104px] 3sm:h-[90px] xs:h-[80px] w-full 2xs:h-[80px] 3xs:h-[100px] bg-gradient-slider absolute bottom-0 left-0 p-4  1xs:p-1 text-white flex flex-wrap"
                                >
                                    <div className="w-full">
                                        <p className="slider-title">
                                            <Sanitized html={item[`title_${i18n.language}`]} />
                                        </p>
                                        <div className="flex justify-between mt-[13px] 1xs:mt-[8px]">
                                            <p className="font-normal text-[15px] text-white font-general">
                                                {t('investigate')}
                                            </p>
                                            <p className="font-medium text-base font-general text-white">
                                                {item.date.split('-').reverse().join('.')}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </SwiperSlide>
                    ))}
                <div
                    ref={navigationPrevRef}
                    className="banner-prev absolute top-[44%] left-[20px]"
                ></div>
                <div
                    ref={navigationNextRef}
                    className="banner-next absolute top-[45%] right-[20px]"
                ></div>
            </Swiper>
            <div className="swiper-custom-pagination" />
            {openRegisterModal && (
                <MobileForm
                    openRegisterModal={openRegisterModal}
                    setOpenRegisterModal={() => setOpenRegisterModal(false)}
                />
            )}
        </div>
    );
};

export default Banner;