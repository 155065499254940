import React, {useRef, useState} from 'react';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import '../../../index.css'
import {Field, Form, Formik, useFormik} from "formik";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import Modal from "../../../pages/galeryPage/modal";
import {useClickOutside} from "../../../hooks/useOutside";
import {useFetch} from "../../../api/useFetch";
import {base, galeryUrl} from "../../../api/const";
import {validateEmail, validationSchema} from "./schema";
import close from "../../../assets/image/home/close.png";
import {setForm} from "../../../store/searchSlice";
import {asyncForm} from "../../../store/asyncAction";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {PhoneNumberUtil} from "google-libphonenumber";
import {useMatchMedia} from "../../../hooks/useMatchMedia";

const borderStyle = {
    width: "585px",
    height: "40px",
    border: "1px solid black",
    borderRadius: "5px",
    marginLeft:"5px"
};

const tabletStyle = {
    width: "315px",
    height: "40px",
    border: "1px solid black",
    borderRadius: "5px",
    marginLeft:"5px"
};


const buttonStyle = {
    width: "50px",
    height: "40px",
    border: "1px solid black",
};

const MobileForm = ({openRegisterModal, setOpenRegisterModal}) => {
    const [ref] = useClickOutside(() => setOpenRegisterModal(false))
    document.body.style.overflow = "hidden";
    const id = localStorage.getItem('id')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const [error, setError] = useState(false)
    const phoneUtil = PhoneNumberUtil.getInstance();
    const response = useSelector((state) => state.search.form)
    const { isMobile, isTablet, isDesktop } = useMatchMedia();
    const swiperRef = useRef();
    const phoneInputRef = useRef(null);
    const [value, setValue ] = useState()
    const successRu = "Данные успешно отправлены"
    const successEn = "Data successfully submitted"

    const onClose = () => {
        setOpenRegisterModal(false)
        document.body.style.overflow = "";
    }

    return (
            <Modal open={openRegisterModal}>
                <div >
            <Formik
                initialValues={{
                    // phone: "",
                    email: "",
                    organization: "",
                    description: "",
                    agree: false
                }}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    if (values && value) {
                        dispatch(setForm(value))
                        try {
                            dispatch(asyncForm({values, value, success:successRu }))
                            dispatch(setForm("996"))
                            resetForm()
                            onClose()
                            //     const phoneNumber = phoneUtil.parse(values.phone);
                            //     const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
                            //     if (phoneUtil.isValidNumber(phoneNumber)) {
                            //         console.log(`The phone number (${phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)}) is valid for country ${countryCode}.`);
                            //     } else {
                            //         console.log('The phone number is not valid.');
                            //         setError(true);
                            //     }
                        } catch (error) {
                            toast.error('Не удалось загрузить');
                            console.log('Invalid phone number format.');
                            // setError(true);
                        }} else {
                        toast.error("Повторите попытку")
                    }
                }}
            >
                {({ values, setFieldValue }) => (

                    <Form className="w-[330px] h-[380px] m-auto py-4 px-[5px]">
                        <div className="flex justify-end mr-[5px] mb-[7px] cursor-pointer">
                            <img src={close} alt="close icon" onClick={onClose}/>
                        </div>
                        <div className="mb-[20px] text-lg font-bold leading-[21px] text-center">{t('become')}</div>
                          <div className="input-wrap">
                            <label htmlFor="organization" className="mobile-label ">
                                {t("organization")}</label>
                            <Field
                                name="organization"
                                type="text"
                                className="mobile-input"
                                placeholder={t("organization")}
                                required
                            />
                        </div>
                          <div className="input-wrap">
                            { error === false && <label htmlFor="number" className="mobile-label">
                                {t("phone")}</label>}
                            { error === true && <label htmlFor="number" className="error-label">
                                {t("error")}</label>}
                                   <PhoneInput
                                      name="phone"
                                      inputStyle={tabletStyle}
                                      buttonStyle={buttonStyle}
                                      id="contactInput"
                                      country={"kg"}
                                      onChange={(value) => setValue( value)}
                                  />
                              {/*{ isDesktop && (*/}
                              {/*    <PhoneInput*/}
                              {/*        name="phone"*/}
                              {/*        inputStyle={borderStyle}*/}
                              {/*        buttonStyle={buttonStyle}*/}
                              {/*        id="contactInput"*/}
                              {/*        country={"kg"}*/}
                              {/*        onChange={(value) => setFieldValue("phone", value)}*/}
                              {/*    />*/}
                              {/*)}*/}
                        </div>
                          <div className="input-wrap ">
                            <label htmlFor="email" className="mobile-label mt-2">
                                {t("email")}</label>
                            <Field
                                name="email"
                                type="email"
                                className="mobile-input"
                                placeholder="mail@example.com"
                                validate={validateEmail()}
                                required/>
                        </div>
                          <div className="input-wrap">
                            <label htmlFor="description"
                                   className="mobile-label"> {t("description")}</label>
                            <Field
                                name="description"
                                placeholder={t("description")}
                                type="text"
                                className="mobile-input"
                                required
                            />
                        </div>
                        <div className="flex items-start align-middle mb-[22px] mt-[30px]">
                            <div className="flex items-center">
                                <Field
                                    required
                                    name="agree"
                                    id="terms"
                                    type="checkbox"
                                    className="w-[20px] h-[20px] border mr-[10px] border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                />
                            </div>
                            <label htmlFor="agree" className="text-xs leading-[12px] font-normal h-[20px] flex items-center">
                                {t("term")}
                            </label>
                        </div>
                        <button type="submit"
                                className="w-[320px] rounded-[4px] h-[40px] bg-blue text-white text-center ">
                            {t("send")}
                        </button>
                    </Form>
                )}
            </Formik>
                </div>
            </Modal>
    );
};

export default MobileForm;