import React from "react";
import {useNavigate} from "react-router";
import {useMatchMedia} from "../../hooks/useMatchMedia";


export const BreadCrumbs = (props) => {
    const { isMobile, isTablet, isDesktop } = useMatchMedia();
    const navigate = useNavigate()

    const isLast = (index) => {
        return index === props.crumbs.length - 1;
    };

    return (
      <div className="flex mb-8 items-center md:mb-0">
        {props.crumbs.map((crumb, i) => {
          return (
            <div
              className="text-base font-medium text-grey sm:text-base xs:text-sm"
              key={i}
            >
              <div
                onClick={() => navigate(-1)}
                className="text-grey cursor-pointer text-sm font-normal mx-0.5"
              >
                {crumb}
              </div>
            </div>
          );
        })}
        {isDesktop && (
          <span className="text-grey cursor-pointer text-base font-normal">
            {props.title.length > 20
              ? props.title.split('').splice(0, 30)
              : props.title}
            {props.title.length > 25 && <span> ... </span>}
          </span>
        )}
        {isTablet && (
          <span className="text-grey cursor-pointer text-sm font-normal">
            {props.title.length > 10
              ? props.title.split('').splice(0, 15)
              : props.title}
            {props.title.length > 10 && <span> ... </span>}
          </span>
        )}
        {isMobile && (
          <span className="text-grey cursor-pointer text-sm font-normal">
            {props.title.length > 10
              ? props.title.split('').splice(0, 12)
              : props.title}
            {props.title.length > 8 && <span> ... </span>}
          </span>
        )}
      </div>
    );
};