import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {api, main, searchUrl} from "../api/const";
import {getSearchSuccess, setForm} from "./searchSlice";
import { getCookie } from 'react-use-cookie';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const csrfToken = getCookie('csrftoken');


export const asyncSearch = createAsyncThunk(
  'search/fetchMaterial',
  async ({ value}, { rejectWithValue, dispatch }) => {

    try {
        const queryValue = value || 'null'; // Set query value to null if value is empty
        const response = await axios({
            method: 'get',
            url: main + `search/?query=${queryValue}`,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            dispatch(getSearchSuccess(response.data));
        } else {
            dispatch(getSearchSuccess([])); // Dispatch empty array on error
        }
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
);

export const asyncForm = createAsyncThunk(
    'search/fetchMaterial',
    async ({ values, value }, { rejectWithValue, dispatch }) => {
        try {
        const response = await axios({
          method: 'post',
          url: main + 'send-apply/',
          data: {
              org_name: `${values.organization}`,
              phone: `${value}`,
              email: `${values.email}`,
              field: `${values.description}`,
          },
        });
            toast.success(response.data.message);
      } catch (error) {
            toast.error("Что-то пошло не так, повторите попытку чуть позже")
            // toast.error("Технические неполадки, повторите попытку чуть позже")
            console.log('form', error)
        return rejectWithValue(error.message);
      }
    }
);

