import MainPage from "../pages/mainPage";
import ResourcesPage from "../pages/resourcesPage";
import AQCAPage from '../pages/AQCAPage';
import databasePage from '../pages/databasePage';
import GrantPage from '../pages/grantPage/grantPage';
import EventPage from '../pages/EventPage/eventPage';
import DetailEvent from '../pages/EventPage/detailEvent';
import ExpertPage from '../pages/ExpertPage/expertPage';
import DetailInvestigate from '../pages/investiagatePage/detailInvestigate';
import SearchPage from '../pages/searchPage';
import DetailGrant from '../pages/grantPage/detailGrants';
import AdvancementPage from '../pages/progressPage';
import DetailExpert from '../pages/ExpertPage/detailExpert';
import AboutPage from '../pages/aboutPage';
import DetailNetwork from '../pages/NetworkingPage/detailNetwork';
import ExpertCountry from '../pages/ExpertPage/countryExpert';
import NetworkContainer from '../pages/NetworkingPage/networkContainer';
import NewsPage from '../pages/investiagatePage/investigatePage';
import DetailAnalytic from '../pages/analyticPage/detailAnalytic';
import SolutionsPage from '../pages/analyticPage/analyticPage';
import { ResearchPage } from '../pages/SolutionPage/solutiontPage';
import SolutionDetail from '../pages/SolutionPage/solutionlDetailt';
import detailNews from '../pages/NewsPage/detailNews';
import AnalyticsPage from "../pages/NewsPage/newsPage";

export const publicRoutes = [
  { path: '/', component: MainPage },
  { path: '/resources', component: ResourcesPage },
  { path: '/aqca', component: AQCAPage },
  // { path: '/solutionsCountry/:id', component: SolutionsPage },
  { path: '/solutions/:id', component: DetailAnalytic },
  // { path: '/newsCountry/:id', component: NewsPage },
  { path: '/news/:id', component: DetailInvestigate },
  { path: '/progress', component: AdvancementPage },
  { path: '/database', component: databasePage },
  { path: '/grants', component: GrantPage },
  { path: '/grants/:id', component: DetailGrant },
  { path: '/expert_country/:id', component: ExpertPage },
  { path: '/expert/:id', component: DetailExpert },
  { path: '/analytics', component: AnalyticsPage },
  { path: '/events', component: EventPage },
  { path: '/analytics/:id', component: detailNews },
  { path: '/events/:id', component: DetailEvent },
  { path: '/investigate/:id', component: SolutionDetail },
  { path: '/investigate', component: ResearchPage },
  { path: '/about', component: AboutPage },
  { path: '/search', component: SearchPage },
  { path: '/network', component: NetworkContainer },
  { path: '/network/:id', component: DetailNetwork },
  { path: '/expert_country', component: ExpertCountry },
  { path: '/solutions', component: SolutionsPage },
  { path: '/news', component: NewsPage },
];
